import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { toast } from 'react-hot-toast';
import { FiArrowLeft, FiPlus, FiTrash2 } from 'react-icons/fi';

interface VideoData {
  title: string;
  description: string;
  youtubeUrl: string;
  thumbnailUrl?: string;
}

interface VideoGallery {
  title: string;
  description: string;
  videos: VideoData[];
  createdAt: any;
  updatedAt: any;
}

const EditVideoGallery: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [videos, setVideos] = useState<VideoData[]>([]);
  const [newVideoUrl, setNewVideoUrl] = useState('');
  const [newVideoTitle, setNewVideoTitle] = useState('');
  const [newVideoDescription, setNewVideoDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState<{ index: number; title: string } | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fetchGallery = async () => {
      if (!id) return;
      try {
        const docRef = doc(db, 'video-gallery', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data() as VideoGallery;
          setTitle(data.title);
          setDescription(data.description);
          setVideos(data.videos);
        } else {
          toast.error('Gallery not found');
          navigate('/admin/video-gallery');
        }
      } catch (error) {
        console.error('Error fetching gallery:', error);
        toast.error('Failed to fetch gallery');
      } finally {
        setIsFetching(false);
      }
    };

    fetchGallery();
  }, [id, navigate]);

  const extractVideoId = (url: string): string | null => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const getThumbnailUrl = (videoId: string): string => {
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  };

  const addVideo = () => {
    if (!newVideoUrl.trim()) {
      toast.error('Please enter a YouTube URL');
      return;
    }

    const videoId = extractVideoId(newVideoUrl);
    if (!videoId) {
      toast.error('Invalid YouTube URL');
      return;
    }

    const newVideo: VideoData = {
      title: newVideoTitle.trim() || 'Untitled Video',
      description: newVideoDescription.trim(),
      youtubeUrl: newVideoUrl.trim(),
      thumbnailUrl: getThumbnailUrl(videoId)
    };

    setVideos([...videos, newVideo]);
    setNewVideoUrl('');
    setNewVideoTitle('');
    setNewVideoDescription('');
    toast.success('Video added to gallery');
  };

  const handleDeleteClick = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    setVideoToDelete({ 
      index, 
      title: videos[index].title 
    });
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (videoToDelete === null) return;
    
    setIsDeleting(true);
    try {
      const updatedVideos = videos.filter((_, i) => i !== videoToDelete.index);
      setVideos(updatedVideos);
      
      if (id) {
        const docRef = doc(db, 'video-gallery', id);
        await updateDoc(docRef, {
          videos: updatedVideos,
          updatedAt: serverTimestamp()
        });
      }
      
      toast.success('Video removed from gallery');
      setDeleteModalOpen(false);
      setVideoToDelete(null);
    } catch (error) {
      console.error('Error removing video:', error);
      toast.error('Failed to remove video');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteModalOpen(false);
    setVideoToDelete(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!title.trim()) {
      toast.error('Please enter a gallery title');
      return;
    }

    if (videos.length === 0) {
      toast.error('Please add at least one video');
      return;
    }

    setIsLoading(true);

    try {
      const galleryData: Partial<VideoGallery> = {
        title: title.trim(),
        description: description.trim(),
        videos,
        updatedAt: serverTimestamp()
      };

      await updateDoc(doc(db, 'video-gallery', id!), galleryData);
      toast.success('Video gallery updated successfully');
      navigate('/admin/video-gallery');
    } catch (error) {
      console.error('Error updating video gallery:', error);
      toast.error('Failed to update video gallery');
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetching) {
    return (
      <div className="min-h-screen bg-gray-900 text-white p-6 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center mb-6">
          <button
            onClick={() => navigate('/admin/video-gallery')}
            className="mr-4 p-2 text-gray-400 hover:text-white transition-colors"
          >
            <FiArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold">Edit Video Gallery</h1>
        </div>

        <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Left Column - Gallery Details & Add Video */}
          <div className="space-y-6">
            {/* Gallery Details */}
            <div className="bg-gray-800 rounded-xl p-6">
              <h2 className="text-xl font-semibold mb-4">Gallery Details</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-2">Gallery Title</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-700 rounded-lg border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Enter gallery title"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-2">Gallery Description</label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-700 rounded-lg border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    rows={4}
                    placeholder="Enter gallery description"
                  />
                </div>
              </div>
            </div>

            {/* Add Video Form */}
            <div className="bg-gray-800 rounded-xl p-6">
              <h2 className="text-xl font-semibold mb-4">Add Video</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-2">YouTube URL</label>
                  <input
                    type="text"
                    value={newVideoUrl}
                    onChange={(e) => setNewVideoUrl(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-700 rounded-lg border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Enter YouTube video URL"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-2">Video Title</label>
                  <input
                    type="text"
                    value={newVideoTitle}
                    onChange={(e) => setNewVideoTitle(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-700 rounded-lg border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    placeholder="Enter video title"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-2">Video Description</label>
                  <textarea
                    value={newVideoDescription}
                    onChange={(e) => setNewVideoDescription(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-700 rounded-lg border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    rows={3}
                    placeholder="Enter video description"
                  />
                </div>
                <button
                  type="button"
                  onClick={addVideo}
                  className="w-full flex items-center justify-center px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
                >
                  <FiPlus className="mr-2" />
                  Add Video
                </button>
              </div>
            </div>
          </div>

          {/* Right Column - Video Preview */}
          <div className="space-y-6">
            <div className="bg-gray-800 rounded-xl p-6 h-full">
              <h2 className="text-xl font-semibold mb-4">Video Preview</h2>
              {videos.length === 0 ? (
                <div className="flex items-center justify-center h-48 bg-gray-700 rounded-lg border-2 border-dashed border-gray-600">
                  <p className="text-gray-400">No videos added yet</p>
                </div>
              ) : (
                <div className="space-y-4">
                  {videos.map((video, index) => (
                    <div key={index} className="bg-gray-700 rounded-lg overflow-hidden">
                      <div className="relative">
                        {video.thumbnailUrl && (
                          <img
                            src={video.thumbnailUrl}
                            alt={video.title}
                            className="w-full h-48 object-cover"
                          />
                        )}
                        <button
                          onClick={(e) => handleDeleteClick(e, index)}
                          type="button"
                          className="absolute top-2 right-2 p-2 text-red-500 hover:text-red-400 transition-colors"
                          title="Remove video"
                        >
                          <FiTrash2 className="w-5 h-5" />
                        </button>
                      </div>
                      <div className="p-4">
                        <h3 className="font-medium mb-1">{video.title}</h3>
                        {video.description && (
                          <p className="text-sm text-gray-300 line-clamp-2">{video.description}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Action Buttons */}
            <div className="flex gap-4">
              <button
                type="submit"
                disabled={isLoading}
                className={`flex-1 px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg font-medium transition-colors flex items-center justify-center ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isLoading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Updating...
                  </>
                ) : (
                  'Update Gallery'
                )}
              </button>
              <button
                type="button"
                onClick={() => navigate('/admin/video-gallery')}
                className="flex-1 px-6 py-3 bg-gray-700 hover:bg-gray-600 rounded-lg font-medium transition-colors"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteModalOpen && videoToDelete !== null && (
        <div 
          className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
          onClick={(e) => handleDeleteCancel(e)}
        >
          <div 
            className="bg-dark-secondary p-6 rounded-xl max-w-md w-full mx-4 space-y-4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center gap-3 text-red-500">
              <FiTrash2 className="w-6 h-6" />
              <h3 className="text-xl font-semibold">Delete Video</h3>
            </div>
            <p className="text-gray-300">
              Are you sure you want to delete <span className="font-semibold">"{videoToDelete.title}"</span> from this gallery? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={(e) => handleDeleteCancel(e)}
                type="button"
                className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button
                onClick={(e) => handleDeleteConfirm(e)}
                type="button"
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors flex items-center gap-2"
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <>
                    <span className="animate-spin">⌛</span>
                    Deleting...
                  </>
                ) : (
                  'Delete'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditVideoGallery;
