import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, onSnapshot, query, doc, deleteDoc } from 'firebase/firestore';
import { auth } from '../../config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import { FiEdit, FiTrash2, FiUser, FiSearch, FiPlus, FiChevronDown } from 'react-icons/fi';

interface UserDetails {
  id: string;
  aadhaarBack: string;
  aadhaarFront: string;
  aadhaarNumber: string;
  authDisplayName: string;
  authEmail: string;
  authUid: string;
  bloodGroup: string;
  createdAt: any;
  createdBy: string;
  currentAddressLine1: string;
  currentAddressLine2: string;
  currentCityVillage: string;
  currentDistrict: string;
  currentPincode: string;
  currentState: string;
  currentStdCode: string;
  dateOfBirth: string;
  education: string;
  emailVerified: boolean;
  enrollmentNumber: string;
  fatherOrHusbandName: string;
  firstName: string;
  fullName: string;
  gender: string;
  history: Array<{
    action: string;
    timestamp: any;
    updatedBy: string;
  }>;
  identificationMark: string;
  languagesKnown: string;
  lastName: string;
  lastUpdatedBy: string;
  memberStatus: string;
  memberType: string;
  officeCityVillage: string;
  officeDistrict: string;
  officeState: string;
  permanentAddressLine1: string;
  permanentAddressLine2: string;
  permanentCityVillage: string;
  permanentDistrict: string;
  permanentPincode: string;
  permanentState: string;
  permanentStdCode: string;
  phone: string;
  photo: string;
  profileImage: string;
  postHeld: string;
  profession: string;
  profileUpdated: boolean;
  promissoryNote: string;
  range: string;
  rank: string;
  sameAsCurrent: boolean;
  uid: string;
  updatedAt: any;
  userRole: string;
  validity: any;
  validityExpirationHandler: {
    lastChecked: any;
    shouldDeactivate: boolean;
    validityDate: any;
  };
  vehicleRegistration: string;
  witness1Aadhaar: string;
  witness1Name: string;
  witness1Phone: string;
  witness2Aadhaar: string;
  witness2Name: string;
  witness2Phone: string;
  designation: string;
}

const UsersList: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('pending');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    // First, check authentication
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (!user) {
        console.log('No user authenticated, redirecting to login...');
        navigate('/login');
        return;
      }

      console.log('Authenticated user:', user.email);
      
      // Then fetch user details
      const db = getFirestore();
      const usersRef = collection(db, 'userDetails');
      const q = query(usersRef);

      const unsubscribeSnapshot = onSnapshot(q, 
        (snapshot) => {
          console.log('Snapshot received:', snapshot.size, 'documents');
          const userList = snapshot.docs.map(doc => {
            const data = doc.data();
            
            // Check if user has only basic details (name + email) or (name + email + phone)
            const hasOnlyNameAndEmail = !!(
              data.fullName && 
              data.authEmail && 
              !data.phone
            );

            const hasOnlyBasicDetails = !!(
              data.fullName && 
              data.authEmail && 
              data.phone
            );

            // User is inactive if they only have name+email OR name+email+phone
            const isInactive = (
              // Check if all other fields are empty when user has only name and email
              hasOnlyNameAndEmail ||
              // OR check if all other fields are empty when user has name, email, and phone
              (hasOnlyBasicDetails &&
                !data.memberType &&
                !data.enrollmentNumber &&
                !data.aadhaarNumber &&
                !data.currentAddressLine1 &&
                !data.dateOfBirth &&
                !data.education &&
                !data.fatherOrHusbandName &&
                !data.gender &&
                !data.identificationMark &&
                !data.profession &&
                !data.postHeld &&
                !data.designation)
            );

            // Set status based on profile completion
            const memberStatus = isInactive ? 'inactive' : (data.memberStatus || 'pending');

            // Log each document's key fields
            console.log('Processing document:', {
              id: doc.id,
              fullName: data.fullName,
              email: data.authEmail,
              phone: data.phone,
              memberType: data.memberType,
              memberStatus: memberStatus,
              hasOnlyNameAndEmail,
              hasOnlyBasicDetails,
              isInactive
            });

            return {
              ...data,
              id: doc.id,
              memberStatus: memberStatus
            } as unknown as UserDetails;
          });
          console.log('All processed users:', userList);
          setUsers(userList);
          setIsLoading(false);
        },
        (error) => {
          console.error('Error fetching users:', error);
          toast.error('Failed to fetch users');
          setIsLoading(false);
        }
      );

      return () => {
        unsubscribeSnapshot();
      };
    });

    return () => {
      unsubscribeAuth();
    };
  }, [navigate]);

  const filteredUsers = users.filter(user => {
    const searchString = searchTerm.toLowerCase();
    const name = user.fullName?.toLowerCase() || '';
    const email = user.authEmail?.toLowerCase() || '';
    const phone = user.phone?.toLowerCase() || '';
    const enrollmentNumber = user.enrollmentNumber?.toLowerCase() || '';
    
    const matchesSearch = 
      name.includes(searchString) ||
      email.includes(searchString) ||
      phone.includes(searchString) ||
      enrollmentNumber.includes(searchString);
    
    const matchesStatus = statusFilter === 'all' || user.memberStatus?.toLowerCase() === statusFilter.toLowerCase();

    // Log filtering results
    console.log('Filtering user:', {
      name,
      email,
      matchesSearch,
      matchesStatus,
      statusFilter,
      userStatus: user.memberStatus
    });

    return matchesSearch && matchesStatus;
  });

  // Log filtered results
  console.log('Filtered users count:', filteredUsers.length);

  const handleEditUser = (userId: string) => {
    navigate(`/admin/users/edit/${userId}`);
  };

  const handleDeleteUser = async (userId: string) => {
    if (!auth.currentUser) {
      toast.error('You must be logged in to delete users');
      navigate('/login');
      return;
    }

    if (window.confirm('Are you sure you want to delete this user?')) {
      setIsDeleting(true);
      try {
        const db = getFirestore();
        const userRef = doc(db, 'userDetails', userId);
        await deleteDoc(userRef);
        toast.success('User deleted successfully');
      } catch (error) {
        console.error('Error deleting user:', error);
        toast.error('Failed to delete user');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100">
      {/* Header Section */}
      <div className="px-6 py-8">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div>
            <h1 className="text-2xl font-bold text-white">Member Management</h1>
            <p className="text-gray-400 mt-1">Manage and monitor all members</p>
          </div>
          <button
            onClick={() => navigate('/admin/users/add')}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-all duration-200 shadow-lg hover:shadow-blue-500/25"
          >
            <FiPlus className="text-lg" />
            <span>Add New Member</span>
          </button>
        </div>

        {/* Search and Filter Section */}
        <div className="mt-8 grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="md:col-span-8 relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search by name, email, phone or enrollment number..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 pr-4 py-3 bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
            />
          </div>
          <div className="md:col-span-4">
            <div className="relative">
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="block w-full pl-4 pr-10 py-3 bg-gray-800 border border-gray-700 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              >
                <option value="all">All Status</option>
                <option value="active">Active Members</option>
                <option value="inactive">Inactive Members</option>
                <option value="pending">Pending Approval</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <FiChevronDown className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="px-6 pb-8">
          {/* Grid View */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredUsers.map((user) => (
              <div
                key={user.id}
                className="bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden border border-gray-700/50 hover:border-blue-500/50 transition-all duration-300 group"
              >
                <div className="p-6">
                  <div className="flex justify-between">
                    {/* Left Side - Photo, Name, and Designation */}
                    <div className="flex flex-col items-start gap-4">
                      {user.photo ? (
                        <img
                          src={user.photo}
                          alt={user.fullName}
                          className="w-16 h-16 rounded-full object-cover ring-2 ring-gray-700"
                        />
                      ) : (
                        <div className="w-16 h-16 rounded-full bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center ring-2 ring-gray-700">
                          <FiUser className="text-white text-2xl" />
                        </div>
                      )}
                      <div>
                        <h3 className="font-semibold text-white text-lg group-hover:text-blue-400 transition-colors duration-200">
                          {user.fullName}
                        </h3>
                        <p className="text-sm text-gray-400">{user.designation || user.postHeld || 'No Designation'}</p>
                      </div>
                    </div>

                    {/* Right Side - Contact Details */}
                    <div className="flex flex-col gap-2 text-sm">
                      <div className="text-gray-300">
                        <span className="text-gray-400">Email: </span>
                        {user.authEmail}
                      </div>
                      <div className="text-gray-300">
                        <span className="text-gray-400">Phone: </span>
                        {user.phone || 'N/A'}
                      </div>
                      <div className="text-gray-300">
                        <span className="text-gray-400">Enrollment: </span>
                        {user.enrollmentNumber || 'N/A'}
                      </div>
                      <div className="text-gray-300">
                        <span className="text-gray-400">City: </span>
                        {user.currentCityVillage || 'N/A'}
                      </div>
                      <div className="text-gray-300">
                        <span className="text-gray-400">District: </span>
                        {user.currentDistrict || 'N/A'}
                      </div>
                      <div className="text-gray-300">
                        <span className="text-gray-400">State: </span>
                        {user.currentState || 'N/A'}
                      </div>
                    </div>
                  </div>

                  {/* Action Buttons */}
                  <div className="flex justify-end gap-2 mt-4">
                    <button
                      onClick={() => handleEditUser(user.id)}
                      className="p-2 text-gray-400 hover:text-blue-400 hover:bg-blue-500/10 rounded-lg transition-all duration-200"
                      disabled={isDeleting}
                      title="Edit Member"
                    >
                      <FiEdit size={18} />
                    </button>
                    <button
                      onClick={() => handleDeleteUser(user.id)}
                      className="p-2 text-gray-400 hover:text-red-400 hover:bg-red-500/10 rounded-lg transition-all duration-200"
                      disabled={isDeleting}
                      title="Delete Member"
                    >
                      <FiTrash2 size={18} />
                    </button>
                  </div>

                  {/* Status Tags */}
                  <div className="flex items-center gap-2 mt-4">
                    <span className="px-3 py-1 rounded-full text-xs font-medium bg-blue-500/20 text-blue-400 border border-blue-500/20">
                      {user.memberType || 'Unknown Type'}
                    </span>
                    <span 
                      className={`px-3 py-1 rounded-full text-xs font-medium ${
                        user.memberStatus?.toLowerCase() === 'active'
                          ? 'bg-green-500/20 text-green-400 border border-green-500/20'
                          : user.memberStatus?.toLowerCase() === 'inactive'
                          ? 'bg-red-500/20 text-red-400 border border-red-500/20'
                          : 'bg-yellow-500/20 text-yellow-400 border border-yellow-500/20'
                      }`}
                      title={
                        user.memberStatus?.toLowerCase() === 'inactive' 
                          ? user.phone 
                            ? 'Profile incomplete. Only name, email, and phone are filled.' 
                            : 'Profile incomplete. Only name and email are filled.'
                          : user.memberStatus?.toLowerCase() === 'active'
                          ? 'Member is active'
                          : 'Waiting for approval'
                      }
                    >
                      {user.memberStatus || 'Pending'}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Empty State */}
          {filteredUsers.length === 0 && (
            <div className="flex flex-col items-center justify-center h-64 text-center">
              <div className="bg-gray-800/50 backdrop-blur-sm rounded-full p-4 mb-4">
                <FiUser className="h-8 w-8 text-gray-400" />
              </div>
              <h3 className="text-lg font-medium text-gray-300">No Members Found</h3>
              <p className="text-gray-400 mt-2">
                {searchTerm
                  ? 'Try adjusting your search or filter criteria'
                  : 'Start by adding your first member'}
              </p>
              {!searchTerm && (
                <button
                  onClick={() => navigate('/admin/users/add')}
                  className="mt-4 flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-all duration-200"
                >
                  <FiPlus />
                  <span>Add New Member</span>
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UsersList;
