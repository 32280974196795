import React, { useState, useEffect } from 'react';
import { FiSave, FiBell, FiLock, FiUser, FiMail } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useSession } from '../../contexts/SessionContext';
import {
  updateUserProfile,
  updateEmailNotifications,
  updatePushNotifications,
  updateDesktopNotifications,
  updateSecuritySettings,
  getUserSettings
} from '../../services/settingsService';
import { SecurityUpdateResponse } from '../../types/security';
import EmailSettings from './EmailSettings';

interface Settings {
  profile: {
    name: string;
    email: string;
  };
  notifications: {
    email: boolean;
    push: boolean;
    desktop: boolean;
  };
  security: {
    twoFactor: boolean;
    sessionTimeout: number;
  };
}

const Settings: React.FC = () => {
  const { sessionTimeout, setSessionTimeout } = useSession();
  const [settings, setSettings] = useState<Settings>({
    profile: {
      name: '',
      email: '',
    },
    notifications: {
      email: false,
      push: false,
      desktop: false,
    },
    security: {
      twoFactor: false,
      sessionTimeout: sessionTimeout || 30, // Default to 30 minutes if not set
    },
  });

  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('profile');
  const [showQRCode, setShowQRCode] = useState<string>('');

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const userSettings = await getUserSettings();
        if (userSettings) {
          setSettings(userSettings);
          // Update session timeout in context if it exists in settings
          if (userSettings.security.sessionTimeout) {
            setSessionTimeout(userSettings.security.sessionTimeout);
          }
        }
      } catch (error) {
        console.error('Error loading settings:', error);
        toast.error('Failed to load settings');
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, [setSessionTimeout]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      console.log('Saving settings:', settings);
      
      // Update Profile
      await updateUserProfile(settings.profile.name, settings.profile.email);

      // Update Notifications
      await updateEmailNotifications(settings.notifications.email);
      await updatePushNotifications(settings.notifications.push);
      await updateDesktopNotifications(settings.notifications.desktop);

      // Update Security Settings
      try {
        const securityResult = await updateSecuritySettings(
          settings.security.twoFactor,
          settings.security.sessionTimeout
        );

        setSessionTimeout(settings.security.sessionTimeout);

        if (securityResult?.qrCode && settings.security.twoFactor) {
          setShowQRCode(securityResult.qrCode);
        }
      } catch (error) {
        console.error('Error updating security settings:', error);
        toast.error('Failed to update security settings');
        return;
      }

      // Send test email if email notifications are enabled
      if (settings.notifications.email) {
        try {
          const sendEmail = httpsCallable(getFunctions(), 'sendEmailFunction');
          await sendEmail({
            to: settings.profile.email,
            subject: 'Settings Updated',
            text: 'Your account settings have been successfully updated.'
          });
        } catch (emailError) {
          console.error('Error sending test email:', emailError);
          // Don't return here, continue with other updates
        }
      }

      // Reload settings after all updates
      const updatedSettings = await getUserSettings();
      if (updatedSettings) {
        setSettings(updatedSettings);
      }

      toast.success('Settings saved successfully');
    } catch (error) {
      console.error('Error saving settings:', error);
      toast.error('Failed to save settings. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <div className="max-w-4xl mx-auto">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-3xl font-bold">Settings</h1>
            </div>

            {/* Settings Navigation */}
            <div className="flex space-x-4 mb-6 border-b border-gray-700">
              <button
                onClick={() => setActiveTab('profile')}
                className={`flex items-center gap-2 px-4 py-2 border-b-2 ${
                  activeTab === 'profile' ? 'border-blue-500 text-blue-500' : 'border-transparent'
                }`}
              >
                <FiUser />
                Profile
              </button>
              <button
                onClick={() => setActiveTab('notifications')}
                className={`flex items-center gap-2 px-4 py-2 border-b-2 ${
                  activeTab === 'notifications' ? 'border-blue-500 text-blue-500' : 'border-transparent'
                }`}
              >
                <FiBell />
                Notifications
              </button>
              <button
                onClick={() => setActiveTab('security')}
                className={`flex items-center gap-2 px-4 py-2 border-b-2 ${
                  activeTab === 'security' ? 'border-blue-500 text-blue-500' : 'border-transparent'
                }`}
              >
                <FiLock />
                Security
              </button>
              <button
                onClick={() => setActiveTab('email')}
                className={`flex items-center gap-2 px-4 py-2 border-b-2 ${
                  activeTab === 'email' ? 'border-blue-500 text-blue-500' : 'border-transparent'
                }`}
              >
                <FiMail />
                Email Settings
              </button>
            </div>

            <div className="grid gap-6">
              {/* Profile Section */}
              {activeTab === 'profile' && (
                <div className="bg-gray-800 p-6 rounded-lg">
                  <div className="flex items-center gap-4 mb-6">
                    <FiUser className="text-xl" />
                    <h2 className="text-xl font-semibold">Profile</h2>
                  </div>
                  <div className="grid gap-6">
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium mb-2">Name</label>
                        <input
                          type="text"
                          value={settings.profile.name}
                          onChange={(e) =>
                            setSettings(prev => ({
                              ...prev,
                              profile: { ...prev.profile, name: e.target.value },
                            }))
                          }
                          className="w-full px-4 py-2 bg-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-2">Email</label>
                        <input
                          type="email"
                          value={settings.profile.email}
                          onChange={(e) =>
                            setSettings(prev => ({
                              ...prev,
                              profile: { ...prev.profile, email: e.target.value },
                            }))
                          }
                          className="w-full px-4 py-2 bg-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Notifications Section */}
              {activeTab === 'notifications' && (
                <div className="bg-gray-800 p-6 rounded-lg">
                  <div className="flex items-center gap-4 mb-6">
                    <FiBell className="text-xl" />
                    <h2 className="text-xl font-semibold">Notifications</h2>
                  </div>
                  <div className="space-y-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium">Email Notifications</h3>
                        <p className="text-sm text-gray-400">
                          Receive email updates about your account activity
                        </p>
                      </div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={settings.notifications.email}
                          onChange={(e) =>
                            setSettings(prev => ({
                              ...prev,
                              notifications: {
                                ...prev.notifications,
                                email: e.target.checked,
                              },
                            }))
                          }
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                      </label>
                    </div>

                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium">Push Notifications</h3>
                        <p className="text-sm text-gray-400">
                          Get instant push notifications in your browser
                        </p>
                      </div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={settings.notifications.push}
                          onChange={(e) =>
                            setSettings(prev => ({
                              ...prev,
                              notifications: {
                                ...prev.notifications,
                                push: e.target.checked,
                              },
                            }))
                          }
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                      </label>
                    </div>

                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium">Desktop Notifications</h3>
                        <p className="text-sm text-gray-400">
                          Show notifications on your desktop
                        </p>
                      </div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={settings.notifications.desktop}
                          onChange={(e) =>
                            setSettings(prev => ({
                              ...prev,
                              notifications: {
                                ...prev.notifications,
                                desktop: e.target.checked,
                              },
                            }))
                          }
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {/* Security Section */}
              {activeTab === 'security' && (
                <div className="bg-gray-800 p-6 rounded-lg">
                  <div className="flex items-center gap-4 mb-6">
                    <FiLock className="text-xl" />
                    <h2 className="text-xl font-semibold">Security</h2>
                  </div>
                  <div className="grid gap-6">
                    <div className="space-y-4">
                      <div>
                        <label className="flex items-center justify-between">
                          <span className="text-sm font-medium">Two-Factor Authentication</span>
                          <input
                            type="checkbox"
                            checked={settings.security.twoFactor}
                            onChange={(e) =>
                              setSettings(prev => ({
                                ...prev,
                                security: { ...prev.security, twoFactor: e.target.checked },
                              }))
                            }
                            className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-500"
                          />
                        </label>
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-2">
                          Session Timeout (minutes)
                          <span className="ml-2 text-gray-400">
                            Current: {sessionTimeout} minutes
                          </span>
                        </label>
                        <div className="flex items-center gap-4">
                          <input
                            type="number"
                            min="1"
                            max="1440"
                            value={settings.security.sessionTimeout}
                            onChange={(e) => {
                              const value = parseInt(e.target.value) || 30;
                              if (value >= 1 && value <= 1440) {
                                setSettings(prev => ({
                                  ...prev,
                                  security: { ...prev.security, sessionTimeout: value },
                                }));
                              }
                            }}
                            className="w-full px-4 py-2 bg-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          />
                          <button
                            onClick={async () => {
                              try {
                                setIsSaving(true);
                                console.log('Attempting to update session timeout to:', settings.security.sessionTimeout);
                                
                                const response = await updateSecuritySettings(
                                  settings.security.twoFactor,
                                  settings.security.sessionTimeout
                                );
                                
                                console.log('Update security settings response:', response);
                                
                                if (response.success) {
                                  setSessionTimeout(settings.security.sessionTimeout);
                                  setSettings(prev => ({
                                    ...prev,
                                    security: {
                                      ...prev.security,
                                      sessionTimeout: settings.security.sessionTimeout
                                    }
                                  }));
                                  toast.success('Session timeout updated successfully');
                                }
                              } catch (error) {
                                console.error('Detailed error in session timeout update:', error);
                                if (error instanceof Error) {
                                  console.error('Error message:', error.message);
                                  console.error('Error stack:', error.stack);
                                  toast.error(`Failed to update session timeout: ${error.message}`);
                                } else {
                                  toast.error('Failed to update session timeout');
                                }
                              } finally {
                                setIsSaving(false);
                              }
                            }}
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={isSaving || settings.security.sessionTimeout === sessionTimeout || settings.security.sessionTimeout < 1 || settings.security.sessionTimeout > 1440}
                          >
                            <FiSave className="w-4 h-4" />
                            {isSaving ? 'Saving...' : 'Update'}
                          </button>
                        </div>
                        <div className="mt-2 text-sm text-gray-400">
                          {Math.floor(settings.security.sessionTimeout / 60)} hours {settings.security.sessionTimeout % 60} minutes
                        </div>
                        <p className="mt-1 text-sm text-gray-400">
                          Set how long until your session expires due to inactivity (1-1440 minutes)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Email Settings Section */}
              {activeTab === 'email' && <EmailSettings />}
            </div>

            {/* Save Button (only show for profile, notifications, and security) */}
            {activeTab !== 'email' && (
              <div className="mt-6">
                <button
                  onClick={handleSave}
                  disabled={isSaving}
                  className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSaving ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                      Saving...
                    </>
                  ) : (
                    <>
                      <FiSave className="w-5 h-5" />
                      Save Changes
                    </>
                  )}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Settings;
