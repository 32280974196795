import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Analysis from './pages/Analysis';
import ErrorPage from './components/ErrorPage';
import PrivateRoute from './components/PrivateRoute';
import AdminLayout from './components/AdminLayout';
import { auth } from './config/firebase';

// Users
import AddUser from './pages/users/AddUser';
import EditUser from './pages/users/EditUser';
import PendingUsers from './pages/users/PendingUsers';
import DeleteUser from './pages/users/DeleteUser';
import UsersList from './pages/users/UsersList';
import UserDetails from './pages/users/UserDetails';

// Articles
import AddArticle from './pages/articles/AddArticle';
import EditArticle from './pages/articles/EditArticle';
import ArticlesList from './pages/articles/ArticlesList';
import DraftArticles from './pages/articles/DraftArticles';
import ViewArticle from './pages/articles/ViewArticle';

// Events
import AddEvent from './pages/events/AddEvent';
import EditEvent from './pages/events/EditEvent';
import DeleteEvent from './pages/events/DeleteEvent';
import EventsList from './pages/events/EventsList';
import ViewEvent from './pages/events/ViewEvent';

// Photo Gallery
import AddPhotoGallery from './pages/photo-gallery/AddPhotoGallery';
import EditPhotoGallery from './pages/photo-gallery/EditPhotoGallery';
import DeletePhotoGallery from './pages/photo-gallery/DeletePhotoGallery';
import PhotoGalleryList from './pages/photo-gallery/PhotoGalleryList';

// Video Gallery
import AddVideoGallery from './pages/video-gallery/AddVideoGallery';
import EditVideoGallery from './pages/video-gallery/EditVideoGallery';
import DeleteVideoGallery from './pages/video-gallery/DeleteVideoGallery';
import VideoGalleryList from './pages/video-gallery/VideoGalleryList';

// Notifications
import UserNotificationsList from './pages/user-notifications/UserNotificationsList';
import CreateUserNotification from './pages/user-notifications/CreateUserNotification';
import EditUserNotification from './pages/user-notifications/EditUserNotification';
import DeleteUserNotification from './pages/user-notifications/DeleteUserNotification';

// Admin Notifications
import NotificationsList from './pages/notifications/NotificationsList';
import CreateNotification from './pages/notifications/CreateNotification';
import EditNotification from './pages/notifications/EditNotification';
import DeleteNotification from './pages/notifications/DeleteNotification';

// Dashboard Home
import DashboardHome from './pages/Dashboard/DashboardHome';

// Support
import SupportDashboard from './pages/support/SupportDashboard';
import NewTicket from './pages/support/NewTicket';
import TicketDetail from './pages/support/TicketDetail';

import { AuthProvider } from './contexts/AuthContext';
import { SessionProvider } from './contexts/SessionContext';

function App() {
  useEffect(() => {
    // Set up a periodic token refresh
    const intervalId = setInterval(() => {
      const user = auth.currentUser;
      if (user) {
        user.getIdToken(true)  // Force token refresh
          .then(() => {
            console.log('Token refreshed');
          })
          .catch((error: Error) => {
            console.error('Error refreshing token:', error);
          });
      }
    }, 10 * 60 * 1000); // Refresh every 10 minutes

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Router>
      <AuthProvider>
        <SessionProvider>
          <div className="App bg-dark-primary min-h-screen text-white">
            <Toaster position="top-right" />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/error" element={<ErrorPage />} />
              
              {/* Protected Admin Routes */}
              <Route
                path="/admin"
                element={
                  <AdminLayout>
                    <PrivateRoute>
                      <Outlet />
                    </PrivateRoute>
                  </AdminLayout>
                }
              >
                <Route index element={<Navigate to="dashboard" replace />} />
                <Route path="dashboard" element={<DashboardHome />} />
                
                {/* User Management */}
                <Route path="users">
                  <Route index element={<UsersList />} />
                  <Route path="pending" element={<PendingUsers />} />
                  <Route path="add" element={<AddUser />} />
                  <Route path="edit/:id" element={<EditUser />} />
                  <Route path="delete" element={<DeleteUser />} />
                  <Route path=":id" element={<UserDetails />} />
                </Route>
                
                {/* Article Management */}
                <Route path="articles">
                  <Route index element={<ArticlesList />} />
                  <Route path="add" element={<AddArticle />} />
                  <Route path="edit/:id" element={<EditArticle />} />
                  <Route path="view/:id" element={<ViewArticle />} />
                  <Route path="drafts" element={<DraftArticles />} />
                </Route>

                {/* Events Management */}
                <Route path="events">
                  <Route index element={<EventsList />} />
                  <Route path=":id" element={<ViewEvent />} />
                  <Route path="add" element={<AddEvent />} />
                  <Route path="edit/:id" element={<EditEvent />} />
                  <Route path="delete/:id" element={<DeleteEvent />} />
                </Route>

                {/* Photo Gallery Management */}
                <Route path="photo-gallery">
                  <Route index element={<PhotoGalleryList />} />
                  <Route path="add" element={<AddPhotoGallery />} />
                  <Route path="edit/:id" element={<EditPhotoGallery />} />
                  <Route path="delete/:id" element={<DeletePhotoGallery />} />
                </Route>

                {/* Video Gallery Management */}
                <Route path="video-gallery">
                  <Route index element={<VideoGalleryList />} />
                  <Route path="add" element={<AddVideoGallery />} />
                  <Route path="edit/:id" element={<EditVideoGallery />} />
                  <Route path="delete/:id" element={<DeleteVideoGallery />} />
                </Route>

                {/* User Notifications Management */}
                <Route path="user-notifications">
                  <Route index element={<UserNotificationsList />} />
                  <Route path="create" element={<CreateUserNotification />} />
                  <Route path="edit/:id" element={<EditUserNotification />} />
                  <Route path="delete/:id" element={<DeleteUserNotification />} />
                </Route>

                {/* Admin Notifications Management */}
                <Route path="notifications">
                  <Route index element={<NotificationsList />} />
                  <Route path="create" element={<CreateNotification />} />
                  <Route path="edit/:id" element={<EditNotification />} />
                  <Route path="delete/:id" element={<DeleteNotification />} />
                </Route>
                
                {/* Support routes */}
                <Route path="support">
                  <Route index element={<SupportDashboard />} />
                  <Route path="new-ticket" element={<NewTicket />} />
                  <Route path="ticket/:id" element={<TicketDetail />} />
                </Route>
                
                {/* Settings & Profile */}
                <Route path="profile" element={<Profile />} />
                <Route path="settings" element={<Settings />} />
                
                {/* Analytics */}
                <Route path="analysis" element={<Analysis />} />
              </Route>

              {/* Catch all undefined routes */}
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </div>
        </SessionProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
