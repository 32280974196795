import React, { useState, useEffect, ChangeEvent } from 'react';
import { collection, getDocs, query, orderBy, where, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  IconButton,
  Chip,
  Tooltip,
  TablePagination,
  useTheme,
  alpha,
} from '@mui/material';
import {
  Download as DownloadIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  Visibility as VisibilityIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import * as XLSX from 'sheetjs-style';

interface HeadCell {
  id: keyof UserData;
  label: string;
  numeric: boolean;
}

interface UserData {
  // Required fields
  fullName: string;
  email: string;
  phone: string;
  memberType: string;
  designation: string;
  memberStatus: string;
  enrollmentNumber: string;
  officeState: string;
  officeDistrict: string;
  verificationStatus: string;
  adminVerified: boolean;
  validFrom: string;
  validUntil: string;

  // Optional fields
  photo?: string;
  dateOfBirth?: string;
  gender?: string;
  aadhaarNumber?: string;
  address?: string;
  district?: string;
  state?: string;
  pinCode?: string;
  witness1Name?: string;
  witness1Phone?: string;
  witness1Aadhaar?: string;
  witness2Name?: string;
  witness2Phone?: string;
  witness2Aadhaar?: string;
  range?: string;
  role?: string;
  userRole?: string;
  emailVerified?: boolean;
  profileUpdated?: boolean;
  validity?: string;
  lastUpdatedBy?: string | null;
  history?: any[];
  aadhaarFront?: string;
  aadhaarBack?: string;
  promissoryNote?: string;
  signedForm?: string;
  authUid?: string;
}

type Order = 'asc' | 'desc';

// Utility function to capitalize first letter of every word
const capitalizeEveryWord = (str: string) => {
  if (!str) return '';
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const headCells: HeadCell[] = [
  { id: 'fullName', label: 'Full Name', numeric: false },
  { id: 'email', label: 'Email', numeric: false },
  { id: 'phone', label: 'Phone', numeric: false },
  { id: 'memberType', label: 'Member Type', numeric: false },
  { id: 'designation', label: 'Designation', numeric: false },
  { id: 'memberStatus', label: 'Member Status', numeric: false },
  { id: 'enrollmentNumber', label: 'Enrollment Number', numeric: false },
  { id: 'verificationStatus', label: 'Verification Status', numeric: false },
  { id: 'adminVerified', label: 'Admin Verified', numeric: false },
  { id: 'validFrom', label: 'Valid From', numeric: false },
  { id: 'validUntil', label: 'Valid Until', numeric: false },
  { id: 'officeState', label: 'Office State', numeric: false },
  { id: 'officeDistrict', label: 'Office District', numeric: false },
];

const UserDetails: React.FC = () => {
  const theme = useTheme();
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);
  const [orderByField, setOrderByField] = useState<keyof UserData>('fullName');
  const [order, setOrder] = useState<Order>('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<UserData[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = localStorage.getItem('userDetailsRowsPerPage');
    return savedRowsPerPage ? parseInt(savedRowsPerPage, 10) : 10;
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter((user) =>
      Object.values(user).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredUsers(filtered);
  }, [searchTerm, users]);

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'userDetails'));
      const fetchedUsers: UserData[] = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          
          // Fetch user details for designation
          let designation = '';
          try {
            const userDetailsDoc = await getDoc(doc.ref);
            if (userDetailsDoc.exists()) {
              const userDetailsData = userDetailsDoc.data();
              designation = userDetailsData.designation || '';
            }
          } catch (error) {
            console.error('Error fetching designation:', error);
          }

          return {
            fullName: data.fullName || '',
            email: data.email || '',
            phone: data.phone || '',
            memberType: data.memberType || '',
            designation: designation, // Use fetched designation
            memberStatus: data.memberStatus || '',
            enrollmentNumber: data.enrollmentNumber || '',
            officeState: data.officeState || '',
            officeDistrict: data.officeDistrict || '',
            verificationStatus: data.verificationStatus || '',
            adminVerified: data.adminVerified || false,
            validFrom: data.validFrom || '',
            validUntil: data.validUntil || '',
            photo: data.photo,
            dateOfBirth: data.dateOfBirth,
            gender: data.gender,
            aadhaarNumber: data.aadhaarNumber,
            address: data.address,
            district: data.district,
            state: data.state,
            pinCode: data.pinCode,
            witness1Name: data.witness1Name,
            witness1Phone: data.witness1Phone,
            witness1Aadhaar: data.witness1Aadhaar,
            witness2Name: data.witness2Name,
            witness2Phone: data.witness2Phone,
            witness2Aadhaar: data.witness2Aadhaar,
            range: data.range,
            role: data.role,
            userRole: data.userRole,
            emailVerified: data.emailVerified,
            profileUpdated: data.profileUpdated,
            validity: data.validity,
            lastUpdatedBy: data.lastUpdatedBy,
            history: data.history,
            aadhaarFront: data.aadhaarFront,
            aadhaarBack: data.aadhaarBack,
            promissoryNote: data.promissoryNote,
            signedForm: data.signedForm,
            authUid: data.authUid,
          };
        })
      );

      console.log('Fetched users with designations:', fetchedUsers.map(user => ({
        fullName: user.fullName,
        designation: user.designation
      })));

      setUsers(fetchedUsers);
      setFilteredUsers(fetchedUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  const handleSort = (property: keyof UserData) => {
    const isAsc = orderByField === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderByField(property);

    const sorted = [...filteredUsers].sort((a, b) => {
      const valueA = String(a[property]).toLowerCase();
      const valueB = String(b[property]).toLowerCase();

      if (isAsc) {
        return valueB.localeCompare(valueA);
      } else {
        return valueA.localeCompare(valueB);
      }
    });

    setFilteredUsers(sorted);
  };

  const handleDownloadExcel = () => {
    const excelData = filteredUsers.map((user, index) => ({
      'S.No': index + 1,
      'Full Name': user.fullName,
      'Email': user.email,
      'Phone': user.phone,
      'Member Type': user.memberType,
      'Designation': user.designation,
      'Member Status': user.memberStatus,
      'Enrollment Number': user.enrollmentNumber,
      'Verification Status': user.verificationStatus,
      'Admin Verified': user.adminVerified ? 'Yes' : 'No',
      'Valid From': user.validFrom,
      'Valid Until': user.validUntil,
      'Office State': user.officeState,
      'Office District': user.officeDistrict,
    }));
    
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(workbook, 'user_details.xlsx');
  };

  const handleDownloadCSV = () => {
    const headers = ['S.No', ...headCells.map(cell => cell.label)];
    const data = filteredUsers.map((user, index) => [
      index + 1,
      user.fullName,
      user.email,
      user.phone,
      user.memberType,
      user.designation,
      user.memberStatus,
      user.enrollmentNumber,
      user.verificationStatus,
      user.adminVerified ? 'Yes' : 'No',
      user.validFrom,
      user.validUntil,
      user.officeState,
      user.officeDistrict,
    ]);

    let csvContent = headers.join(',') + '\n';
    data.forEach(row => {
      csvContent += row.map(cell => {
        // Handle cells that contain commas by wrapping in quotes
        if (cell && cell.toString().includes(',')) {
          return `"${cell}"`;
        }
        return cell;
      }).join(',') + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'user_details.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    localStorage.setItem('userDetailsRowsPerPage', newRowsPerPage.toString());
    setPage(0);
  };

  const getStatusColor = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return '#4CAF50';
      case 'pending':
        return '#FF9800';
      case 'rejected':
        return '#FF0000';
      default:
        return '#999';
    }
  };

  const getVerificationChip = (verified: boolean | undefined) => {
    return (
      <Chip
        icon={verified ? <CheckCircleIcon /> : <CancelIcon />}
        label={verified ? 'Verified' : 'Not Verified'}
        color={verified ? 'success' : 'error'}
        size="small"
        variant="outlined"
      />
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 2, bgcolor: '#111827', overflow: 'hidden' }}>
      <Card
        elevation={0}
        sx={{
          mb: 2,
          backgroundColor: '#1f2937',
          borderRadius: 2,
          border: '1px solid #374151',
        }}
      >
        <CardContent sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              mb: 1,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography 
                variant="h6" 
                component="h1" 
                fontWeight="bold"
                sx={{ color: '#f3f4f6' }}
              >
                {capitalizeEveryWord('User Details')}
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Button
                size="small"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadCSV}
                sx={{
                  bgcolor: '#1f2937',
                  color: '#f3f4f6',
                  border: '1px solid #374151',
                  '&:hover': {
                    bgcolor: '#374151',
                  },
                }}
              >
                CSV
              </Button>
              <Button
                size="small"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadExcel}
                sx={{
                  bgcolor: '#1f2937',
                  color: '#f3f4f6',
                  border: '1px solid #374151',
                  '&:hover': {
                    bgcolor: '#374151',
                  },
                }}
              >
                Excel
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(e.target.value)
              }
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: '#9ca3af', mr: 1 }} />,
              }}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 36,
                  '& fieldset': {
                    borderColor: '#374151',
                  },
                  '&:hover fieldset': {
                    borderColor: '#4b5563',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#3b82f6',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  color: '#f3f4f6',
                  '&::placeholder': {
                    color: '#9ca3af',
                    opacity: 1,
                  },
                },
              }}
            />
          </Box>
        </CardContent>
      </Card>

      <Box sx={{ width: '100%', overflow: 'auto' }}>
        <TableContainer 
          component={Paper} 
          sx={{ 
            bgcolor: '#1f2937',
            borderRadius: 2,
            border: '1px solid #374151',
            width: '100%',
            minWidth: '100%',
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#1f2937',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#374151',
              borderRadius: '4px',
            },
          }}
        >
          <Table stickyHeader size="small" aria-label="user details table" sx={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
                <TableCell 
                  sx={{ 
                    bgcolor: '#1f2937', 
                    color: '#f3f4f6',
                    borderBottom: '1px solid #374151',
                    p: 1,
                    width: '40px',
                    minWidth: '40px'
                  }}
                >
                  S.No
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderByField === headCell.id ? order : false}
                    sx={{ 
                      bgcolor: '#1f2937', 
                      color: '#f3f4f6',
                      borderBottom: '1px solid #374151',
                      p: 1,
                      // Adjust widths based on content type
                      ...(headCell.id === 'fullName' && { width: '130px', minWidth: '130px' }),
                      ...(headCell.id === 'email' && { width: '160px', minWidth: '160px' }),
                      ...(headCell.id === 'phone' && { width: '100px', minWidth: '100px' }),
                      ...(headCell.id === 'memberType' && { width: '100px', minWidth: '100px' }),
                      ...(headCell.id === 'designation' && { width: '100px', minWidth: '100px' }),
                      ...(headCell.id === 'memberStatus' && { width: '100px', minWidth: '100px' }),
                      ...(headCell.id === 'enrollmentNumber' && { width: '120px', minWidth: '120px' }),
                      ...(headCell.id === 'verificationStatus' && { width: '120px', minWidth: '120px' }),
                      ...(headCell.id === 'adminVerified' && { width: '100px', minWidth: '100px' }),
                      ...(headCell.id === 'validFrom' && { width: '90px', minWidth: '90px' }),
                      ...(headCell.id === 'validUntil' && { width: '90px', minWidth: '90px' }),
                      ...(headCell.id === 'officeState' && { width: '100px', minWidth: '100px' }),
                      ...(headCell.id === 'officeDistrict' && { width: '100px', minWidth: '100px' }),
                    }}
                  >
                    <TableSortLabel
                      active={orderByField === headCell.id}
                      direction={orderByField === headCell.id ? order : 'asc'}
                      onClick={() => handleSort(headCell.id)}
                      sx={{
                        whiteSpace: 'nowrap',
                        '&.MuiTableSortLabel-root': {
                          color: '#9ca3af',
                          '&:hover': {
                            color: '#f3f4f6',
                          },
                          '&.Mui-active': {
                            color: '#3b82f6',
                          },
                        },
                        '& .MuiTableSortLabel-icon': {
                          color: '#3b82f6 !important',
                        },
                      }}
                    >
                      {capitalizeEveryWord(headCell.label)}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, index) => (
                  <TableRow
                    hover
                    key={index}
                    sx={{
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                      },
                      '& td': {
                        borderBottom: '1px solid #374151',
                        color: '#f3f4f6',
                        p: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }}
                  >
                    <TableCell sx={{ width: '40px' }}>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>
                      <Tooltip title={user.fullName}>
                        <span>{capitalizeEveryWord(user.fullName)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={user.email}>
                        <span>{user.email.toLowerCase()}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{user.phone}</TableCell>
                    <TableCell>{capitalizeEveryWord(user.memberType)}</TableCell>
                    <TableCell>{capitalizeEveryWord(user.designation)}</TableCell>
                    <TableCell>
                      <Chip
                        label={capitalizeEveryWord(user.memberStatus)}
                        size="small"
                        sx={{
                          bgcolor: alpha(getStatusColor(user.memberStatus), 0.1),
                          color: getStatusColor(user.memberStatus),
                          border: `1px solid ${alpha(getStatusColor(user.memberStatus), 0.2)}`,
                          maxWidth: '90px'
                        }}
                      />
                    </TableCell>
                    <TableCell>{user.enrollmentNumber}</TableCell>
                    <TableCell>{capitalizeEveryWord(user.verificationStatus)}</TableCell>
                    <TableCell>{getVerificationChip(user.adminVerified)}</TableCell>
                    <TableCell>{user.validFrom}</TableCell>
                    <TableCell>{user.validUntil}</TableCell>
                    <TableCell>{capitalizeEveryWord(user.officeState)}</TableCell>
                    <TableCell>{capitalizeEveryWord(user.officeDistrict)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: '#f3f4f6',
            borderTop: '1px solid #374151',
            ml: 0,
            '.MuiToolbar-root': {
              pl: 0,
              justifyContent: 'flex-start',
            },
            '.MuiTablePagination-toolbar': {
              justifyContent: 'flex-start',
              pl: 0,
              minWidth: 'auto',
            },
            '.MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-selectIcon, .MuiTablePagination-displayedRows': {
              color: '#f3f4f6',
            },
            '.MuiTablePagination-actions': {
              marginLeft: 2,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default UserDetails;
