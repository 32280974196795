import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiBell, FiEdit2, FiTrash2, FiUser, FiCalendar, FiClock } from 'react-icons/fi';
import { getFirestore, collection, query, where, orderBy, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { toast } from 'react-hot-toast';

interface UserNotification {
  id: string;
  title: string;
  message: string;
  userId: string;
  userName?: string;
  userEmail?: string;
  scheduledDate: string;
  priority: 'low' | 'medium' | 'high';
  status: 'scheduled' | 'sent' | 'failed';
  type: 'private';
  createdAt: any;
  createdBy: string;
  read: boolean;
}

const UserNotificationsList: React.FC = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState<{[key: string]: { fullName: string, email: string }}>({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const db = getFirestore();
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const usersMap: {[key: string]: { fullName: string, email: string }} = {};
        usersSnapshot.docs.forEach(doc => {
          usersMap[doc.id] = { fullName: doc.data().fullName || 'Unknown User', email: doc.data().email || '' };
        });
        setUsers(usersMap);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const db = getFirestore();
        const notificationsRef = collection(db, 'notifications');
        const q = query(
          notificationsRef,
          where('type', '==', 'private'),
          orderBy('createdAt', 'desc')
        );

        const querySnapshot = await getDocs(q);
        const notificationsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          userName: users[doc.data().userId]?.fullName || 'Unknown User',
          userEmail: users[doc.data().userId]?.email || ''
        })) as UserNotification[];

        setNotifications(notificationsData);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        toast.error('Failed to load notifications');
      } finally {
        setIsLoading(false);
      }
    };

    if (Object.keys(users).length > 0) {
      fetchNotifications();
    }
  }, [users]);

  const handleDelete = async (notificationId: string) => {
    if (!window.confirm('Are you sure you want to delete this notification?')) {
      return;
    }

    try {
      const db = getFirestore();
      await deleteDoc(doc(db, 'notifications', notificationId));
      setNotifications(prev => prev.filter(n => n.id !== notificationId));
      toast.success('Notification deleted successfully');
    } catch (error) {
      console.error('Error deleting notification:', error);
      toast.error('Failed to delete notification');
    }
  };

  const filteredNotifications = notifications.filter(notification =>
    notification.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    notification.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (notification.type === 'private' && notification.userName?.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (notification.type === 'private' && notification.userEmail?.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const getPriorityColor = (priority: UserNotification['priority']) => {
    switch (priority) {
      case 'high':
        return 'text-red-400';
      case 'medium':
        return 'text-yellow-400';
      case 'low':
        return 'text-green-400';
      default:
        return 'text-gray-400';
    }
  };

  const getStatusColor = (status: UserNotification['status']) => {
    switch (status) {
      case 'sent':
        return 'text-green-400';
      case 'scheduled':
        return 'text-blue-400';
      case 'failed':
        return 'text-red-400';
      default:
        return 'text-gray-400';
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-white">User Notifications</h1>
        <button
          onClick={() => navigate('/admin/user-notifications/create')}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Create New Notification
        </button>
      </div>

      <div className="mb-6">
        <input
          type="text"
          placeholder="Search notifications..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full px-4 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        {filteredNotifications.length === 0 ? (
          <div className="p-6 text-center text-gray-400">
            No notifications found
          </div>
        ) : (
          <div className="divide-y divide-gray-700">
            {filteredNotifications.map((notification) => (
              <div key={notification.id} className="p-6 hover:bg-gray-700 transition-colors">
                <div className="flex justify-between items-start mb-4">
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold text-white mb-2">
                      {notification.title}
                    </h3>
                    <p className="text-gray-300 mb-4">{notification.message}</p>
                    <div className="flex items-center space-x-4 text-sm text-gray-400">
                      <div className="flex items-center space-x-2">
                        <FiUser className="w-4 h-4" />
                        {notification.type === 'private' ? (
                          <span>{notification.userName} ({notification.userEmail})</span>
                        ) : (
                          <span>All Users</span>
                        )}
                      </div>
                      <div className="flex items-center">
                        <FiCalendar className="mr-2" />
                        {new Date(notification.scheduledDate).toLocaleDateString()}
                      </div>
                      <div className="flex items-center">
                        <FiClock className="mr-2" />
                        {new Date(notification.scheduledDate).toLocaleTimeString()}
                      </div>
                      <div className={`flex items-center ${getPriorityColor(notification.priority)}`}>
                        <FiBell className="mr-2" />
                        {notification.priority.charAt(0).toUpperCase() + notification.priority.slice(1)} Priority
                      </div>
                      <div className={`flex items-center ${getStatusColor(notification.status)}`}>
                        <span className="capitalize">{notification.status}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-2 ml-4">
                    <button
                      onClick={() => navigate(`/admin/user-notifications/edit/${notification.id}`)}
                      className="p-2 text-blue-400 hover:text-blue-300 focus:outline-none"
                    >
                      <FiEdit2 />
                    </button>
                    <button
                      onClick={() => handleDelete(notification.id)}
                      className="p-2 text-red-400 hover:text-red-300 focus:outline-none"
                    >
                      <FiTrash2 />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserNotificationsList;
