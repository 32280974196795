import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, onSnapshot, QuerySnapshot, DocumentData, doc, getDoc, where, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { FaTicketAlt, FaSpinner, FaFilter, FaSearch } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';

interface Ticket {
  id: string;
  title: string;
  description: string;
  status: 'open' | 'in-progress' | 'resolved';
  priority: 'low' | 'medium' | 'high';
  category: string;
  userId: string;
  userName: string;
  userDisplayName: string;
  userPhotoURL: string | null;
  isPrivate: boolean;
  createdAt: any;
  lastUpdated: any;
  lastMessage: string;
  lastMessageTime: any;
  unreadAdmin: boolean;
  unreadUser: boolean;
}

const SupportDashboard = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!currentUser) {
        console.log('No current user for admin check');
        setIsAdmin(false);
        return;
      }
      
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        
        // Check if user is admin
        const userData = userSnap.data();
        const isAdminUser = Boolean(
          userSnap.exists() && (
            userData?.isAdmin === true ||
            userData?.role === 'admin' ||
            currentUser.email === 'admin@gmail.com' ||
            currentUser.email === 'rohitgupta2079@gmail.com'
          )
        );
        
        console.log('Admin check result:', { 
          uid: currentUser.uid,
          email: currentUser.email,
          userData,
          isAdmin: isAdminUser
        });
        
        setIsAdmin(isAdminUser);
        if (!isAdminUser) {
          toast.error('You do not have admin access');
          navigate('/');
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
        toast.error('Error checking admin status');
        navigate('/');
      }
    };
    checkAdminStatus();
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!currentUser) {
      console.log('No current user for ticket fetch');
      setTickets([]);
      setLoading(false);
      return;
    }

    let unsubscribe: () => void = () => {};

    const fetchTickets = async () => {
      try {
        console.log('Starting ticket fetch with params:', {
          userEmail: currentUser.email,
          uid: currentUser.uid,
          currentTime: new Date().toISOString()
        });

        const ticketsRef = collection(db, 'support_tickets');
        const q = query(
          ticketsRef,
          orderBy('lastUpdated', 'desc')
        );

        unsubscribe = onSnapshot(q, (snapshot: QuerySnapshot<DocumentData>) => {
          console.log('Snapshot received:', {
            empty: snapshot.empty,
            size: snapshot.size,
            docs: snapshot.docs.length
          });
          
          const ticketData = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              title: data.title || '',
              description: data.description || '',
              status: data.status || 'open',
              priority: data.priority || 'medium',
              category: data.category || '',
              userId: data.userId || '',
              userName: data.userName || '',
              userDisplayName: data.userDisplayName || '',
              userPhotoURL: data.userPhotoURL || null,
              isPrivate: Boolean(data.isPrivate),
              createdAt: data.createdAt,
              lastUpdated: data.lastUpdated,
              lastMessage: data.lastMessage || '',
              lastMessageTime: data.lastMessageTime,
              unreadAdmin: Boolean(data.unreadAdmin),
              unreadUser: Boolean(data.unreadUser)
            } as Ticket;
          });

          setTickets(ticketData);
          setLoading(false);
        }, (error) => {
          console.error('Error fetching tickets:', error);
          toast.error('Error fetching tickets');
          setLoading(false);
        });
      } catch (error) {
        console.error('Setup error:', error);
        toast.error('Error setting up ticket listener');
        setLoading(false);
      }
    };

    fetchTickets();
    return () => {
      console.log('Cleaning up ticket listener');
      unsubscribe();
    };
  }, [currentUser]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'open':
        return 'bg-yellow-500';
      case 'in-progress':
        return 'bg-blue-500';
      case 'resolved':
        return 'bg-green-500';
      default:
        return 'bg-gray-500';
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'high':
        return 'bg-red-500';
      case 'medium':
        return 'bg-orange-500';
      case 'low':
        return 'bg-green-500';
      default:
        return 'bg-gray-500';
    }
  };

  const filteredTickets = tickets.filter(ticket => {
    const matchesFilter = filter === 'all' || ticket.status === filter;
    
    const searchLower = searchQuery.toLowerCase();
    const matchesSearch = searchQuery === '' || 
      ticket.title?.toLowerCase().includes(searchLower) ||
      ticket.description?.toLowerCase().includes(searchLower) ||
      ticket.userName?.toLowerCase().includes(searchLower);

    const shouldShow = matchesFilter && matchesSearch;

    console.log('Ticket visibility:', {
      id: ticket.id,
      matchesFilter,
      matchesSearch,
      shouldShow
    });

    return shouldShow;
  });

  console.log('Total tickets:', {
    all: tickets.length,
    filtered: filteredTickets.length,
    filter,
    searchQuery
  });

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.3 }
    }
  };

  const handleTicketClick = async (ticket: Ticket) => {
    if (isAdmin && ticket.unreadAdmin) {
      try {
        await updateDoc(doc(db, 'support_tickets', ticket.id), {
          unreadAdmin: false
        });
      } catch (error) {
        console.error('Error updating ticket read status:', error);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
          <FaSpinner className="text-4xl text-blue-500" />
        </motion.div>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-lg text-gray-400">You do not have admin access</p>
      </div>
    );
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="p-6 max-w-7xl mx-auto"
    >
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 gap-4">
        <motion.h1 
          className="text-3xl font-bold text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Support Dashboard
        </motion.h1>
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Link
            to="/admin/support/new-ticket"
            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 flex items-center gap-2 shadow-lg"
          >
            <FaTicketAlt />
            Create New Ticket
          </Link>
        </motion.div>
      </div>

      <motion.div 
        className="bg-gray-800 rounded-xl p-6 mb-6 shadow-lg"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
          <div className="relative flex-1 w-full">
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search tickets..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-3 bg-gray-700 text-white rounded-lg focus:ring-2 focus:ring-blue-500 transition-all duration-300"
            />
          </div>
          <div className="flex gap-4 w-full md:w-auto">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center gap-2 px-4 py-3 bg-gray-700 rounded-lg hover:bg-gray-600 transition-all duration-300"
            >
              <FaFilter />
              Filters
            </button>
          </div>
        </div>

        <AnimatePresence>
          {showFilters && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-4 overflow-hidden"
            >
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {['all', 'open', 'in-progress', 'resolved'].map((status) => (
                  <button
                    key={status}
                    onClick={() => setFilter(status)}
                    className={`px-4 py-2 rounded-lg capitalize transition-all duration-300 transform hover:scale-105 ${
                      filter === status
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                    }`}
                  >
                    {status}
                  </button>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      <motion.div
        variants={containerVariants}
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      >
        {filteredTickets.map((ticket) => (
          <motion.div
            key={ticket.id}
            variants={itemVariants}
            whileHover={{ scale: 1.02 }}
            className="bg-gray-800 rounded-xl p-6 shadow-lg transition-all duration-300"
          >
            <Link to={`/admin/support/ticket/${ticket.id}`} onClick={() => handleTicketClick(ticket)} className="block">
              <div className="flex items-start gap-4 mb-4">
                <div className="flex-shrink-0">
                  {ticket.userPhotoURL ? (
                    <img
                      src={ticket.userPhotoURL}
                      alt={ticket.userDisplayName}
                      className="w-10 h-10 rounded-full"
                    />
                  ) : (
                    <div className="w-10 h-10 rounded-full bg-gray-600 flex items-center justify-center">
                      <span className="text-sm text-white">
                        {ticket.userDisplayName?.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                </div>

                <div className="flex-1">
                  <h3 className="text-lg font-semibold text-white mb-1">{ticket.title}</h3>
                  <div className="flex items-center gap-2 text-sm text-gray-400">
                    <span>{ticket.userDisplayName}</span>
                    <span>•</span>
                    <span>{new Date(ticket.createdAt?.toDate()).toLocaleDateString()}</span>
                  </div>
                </div>

                {ticket.unreadAdmin && (
                  <div className="flex-shrink-0">
                    <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
                      New
                    </span>
                  </div>
                )}
              </div>

              <p className="text-gray-400 text-sm line-clamp-2 mb-4">{ticket.description}</p>

              <div className="flex flex-wrap gap-2 mb-4">
                <span className={`px-3 py-1 rounded-full text-xs font-medium text-white ${getStatusColor(ticket.status)}`}>
                  {ticket.status}
                </span>
                <span className={`px-3 py-1 rounded-full text-xs font-medium text-white ${getPriorityColor(ticket.priority)}`}>
                  {ticket.priority}
                </span>
                <span className="px-3 py-1 rounded-full text-xs font-medium bg-gray-600 text-white">
                  {ticket.category}
                </span>
              </div>

              {ticket.lastMessage && (
                <div className="mt-3 p-3 bg-gray-700 rounded-lg">
                  <p className="text-sm text-gray-300 line-clamp-1">
                    Last message: {ticket.lastMessage}
                  </p>
                  <p className="text-xs text-gray-400 mt-1">
                    {ticket.lastMessageTime ? new Date(ticket.lastMessageTime?.toDate()).toLocaleString() : ''}
                  </p>
                </div>
              )}
            </Link>
          </motion.div>
        ))}
      </motion.div>

      {filteredTickets.length === 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center py-12"
        >
          <p className="text-gray-400 text-lg">No tickets found</p>
        </motion.div>
      )}
    </motion.div>
  );
};

export default SupportDashboard;
