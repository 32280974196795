import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Article } from '../../types/article';
import { FiArrowLeft, FiSave, FiImage, FiType, FiAlignLeft } from 'react-icons/fi';
import toast from 'react-hot-toast';

const EditArticle: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [content, setContent] = useState('');
  const [featuredImage, setFeaturedImage] = useState('');
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      if (!id) return;

      try {
        const docRef = doc(db, 'articles', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const article = { id: docSnap.id, ...docSnap.data() } as Article;
          setHeading(article.heading);
          setSubHeading(article.subHeading ?? '');
          setContent(article.content ?? '');
          setFeaturedImage(article.featuredImage ?? '');
          if (article.featuredImage) {
            setPreviewImage(article.featuredImage);
          }
        } else {
          toast.error('Article not found');
          navigate('/admin/articles');
        }
      } catch (error) {
        console.error('Error fetching article:', error);
        toast.error('Failed to fetch article');
      }
    };

    fetchArticle();
  }, [id, navigate]);

  const handleSubmit = async () => {
    if (!id) return;

    if (!heading.trim() || !content.trim()) {
      toast.error('Heading and content are required');
      return;
    }

    setIsLoading(true);
    try {
      const docRef = doc(db, 'articles', id);
      await updateDoc(docRef, {
        heading,
        subHeading,
        content,
        featuredImage,
        updatedAt: serverTimestamp()
      });

      toast.success('Article updated successfully');
      navigate('/admin/articles');
    } catch (error) {
      console.error('Error updating article:', error);
      toast.error('Failed to update article');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = (url: string) => {
    setFeaturedImage(url);
    if (url.trim()) {
      setPreviewImage(url);
    } else {
      setPreviewImage(null);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-xl border-b border-white/10">
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4 flex items-center justify-between">
            <button
              onClick={() => navigate('/admin/articles')}
              className="inline-flex items-center px-4 py-2 bg-gray-800/50 text-white rounded-xl hover:bg-gray-800 transition-colors"
            >
              <FiArrowLeft className="w-5 h-5 mr-2" />
              Back
            </button>
            <button
              onClick={handleSubmit}
              disabled={isLoading}
              className="inline-flex items-center px-6 py-2 bg-primary hover:bg-primary/90 text-white rounded-xl transition-colors shadow-lg shadow-primary/25 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FiSave className="w-5 h-5 mr-2" />
              {isLoading ? 'Saving...' : 'Update Article'}
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column - Main Content */}
          <div className="lg:col-span-2 space-y-6">
            <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/5 overflow-hidden">
              <div className="p-6">
                <div className="flex items-center gap-2 text-lg font-semibold text-white mb-4">
                  <FiType className="w-5 h-5" />
                  <span>Article Content</span>
                </div>
                <div className="space-y-6">
                  <input
                    type="text"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                    className="w-full bg-gray-900/50 border border-white/10 rounded-xl px-4 py-3 text-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                    placeholder="Article Heading"
                  />
                  <input
                    type="text"
                    value={subHeading}
                    onChange={(e) => setSubHeading(e.target.value)}
                    className="w-full bg-gray-900/50 border border-white/10 rounded-xl px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                    placeholder="Article Subheading (Optional)"
                  />
                </div>
              </div>
            </div>

            <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/5 overflow-hidden">
              <div className="p-6">
                <div className="flex items-center gap-2 text-lg font-semibold text-white mb-4">
                  <FiAlignLeft className="w-5 h-5" />
                  <span>Content</span>
                </div>
                <textarea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  className="w-full h-[500px] bg-gray-900/50 border border-white/10 rounded-xl px-4 py-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50 resize-none"
                  placeholder="Write your article content here..."
                />
              </div>
            </div>
          </div>

          {/* Right Column - Featured Image */}
          <div className="lg:col-span-1">
            <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/5 sticky top-24">
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-2 text-lg font-semibold text-white">
                    <FiImage className="w-5 h-5" />
                    <span>Featured Image</span>
                  </div>
                  {previewImage && (
                    <button
                      onClick={() => {
                        setFeaturedImage('');
                        setPreviewImage(null);
                      }}
                      className="px-2 py-1 text-xs text-red-400 hover:text-red-300 transition-colors"
                    >
                      Remove
                    </button>
                  )}
                </div>
                <div className="space-y-4">
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={featuredImage}
                      onChange={(e) => handleImageChange(e.target.value)}
                      className="flex-1 bg-gray-900/50 border border-white/10 rounded-xl px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                      placeholder="Enter image URL"
                    />
                    <button
                      onClick={() => document.getElementById('imageUpload')?.click()}
                      className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-xl transition-colors"
                    >
                      Browse
                    </button>
                    <input
                      id="imageUpload"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onload = (event) => {
                            if (event.target?.result) {
                              handleImageChange(event.target.result as string);
                            }
                          };
                          reader.readAsDataURL(file);
                        }
                      }}
                      className="hidden"
                    />
                  </div>
                  {previewImage ? (
                    <div className="relative group">
                      <div className="aspect-video rounded-lg overflow-hidden bg-gray-900/50">
                        <img
                          src={previewImage}
                          alt="Featured"
                          className="w-full h-full object-cover"
                          onError={() => setPreviewImage(null)}
                        />
                      </div>
                      <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <button
                          onClick={() => document.getElementById('imageUpload')?.click()}
                          className="px-4 py-2 bg-white/10 hover:bg-white/20 text-white rounded-lg backdrop-blur-sm transition-colors"
                        >
                          Change Image
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div 
                      onClick={() => document.getElementById('imageUpload')?.click()}
                      className="aspect-video rounded-lg bg-gray-900/50 flex flex-col items-center justify-center text-gray-400 cursor-pointer hover:bg-gray-900/70 transition-colors"
                    >
                      <FiImage className="w-8 h-8 mb-2" />
                      <span>Click to add image</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditArticle;
