import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Article } from '../../types/article';
import { FiArrowLeft, FiEdit2 } from 'react-icons/fi';
import { format } from 'date-fns';

const ViewArticle: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      if (!id) return;

      try {
        setLoading(true);
        const docRef = doc(db, 'articles', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setArticle({
            id: docSnap.id,
            ...docSnap.data()
          } as Article);
        } else {
          setError('Article not found');
        }
      } catch (error) {
        console.error('Error fetching article:', error);
        setError('Failed to fetch article');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 p-6">
        <div className="max-w-4xl mx-auto">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-700/50 rounded-lg w-3/4 mb-4"></div>
            <div className="h-4 bg-gray-700/50 rounded-lg w-1/2 mb-8"></div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-700/50 rounded-lg w-full"></div>
              <div className="h-4 bg-gray-700/50 rounded-lg w-full"></div>
              <div className="h-4 bg-gray-700/50 rounded-lg w-3/4"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !article) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 p-6">
        <div className="max-w-4xl mx-auto text-center">
          <div className="text-red-400 text-lg mb-4">{error}</div>
          <button
            onClick={() => navigate('/admin/articles')}
            className="inline-flex items-center px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors"
          >
            <FiArrowLeft className="w-5 h-5 mr-2" />
            Back to Articles
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-xl border-b border-white/10">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-6 flex items-center justify-between gap-4">
            <button
              onClick={() => navigate('/admin/articles')}
              className="inline-flex items-center px-4 py-2 bg-gray-800/50 text-white rounded-xl hover:bg-gray-800 transition-colors"
            >
              <FiArrowLeft className="w-5 h-5 mr-2" />
              Back
            </button>
            <button
              onClick={() => navigate(`/admin/articles/edit/${article.id}`)}
              className="inline-flex items-center px-4 py-2 bg-primary hover:bg-primary/90 text-white rounded-xl transition-colors"
            >
              <FiEdit2 className="w-5 h-5 mr-2" />
              Edit Article
            </button>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Featured Image */}
        {article.featuredImage && (
          <div className="aspect-video rounded-xl overflow-hidden mb-8">
            <img
              src={article.featuredImage}
              alt={article.heading}
              className="w-full h-full object-cover"
            />
          </div>
        )}

        {/* Article Header */}
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-white mb-4">{article.heading}</h1>
          {article.subHeading && (
            <p className="text-xl text-gray-400 mb-4">{article.subHeading}</p>
          )}
          
          <div className="flex items-center gap-6 text-sm text-gray-500">
            {article.authorName && (
              <div className="flex items-center gap-2">
                <span>By {article.authorName}</span>
              </div>
            )}
            {article.createdAt && (
              <div>
                Published on {format(article.createdAt.toDate(), 'MMMM d, yyyy')}
              </div>
            )}
            <div className={`
              px-2 py-1 text-xs rounded-full
              ${article.status === 'published' ? 'bg-green-500/20 text-green-400' : 
                article.status === 'draft' ? 'bg-gray-500/20 text-gray-400' : 
                'bg-yellow-500/20 text-yellow-400'}
            `}>
              {article.status ? article.status.charAt(0).toUpperCase() + article.status.slice(1) : 'Draft'}
            </div>
          </div>
        </div>

        {/* Article Content */}
        {article.content ? (
          <div 
            className="prose prose-invert max-w-none prose-headings:text-white prose-p:text-gray-300"
            dangerouslySetInnerHTML={{ __html: article.content }}
          />
        ) : (
          <div className="text-gray-400 text-center py-8">
            No content available
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewArticle;
