import React, { createContext, useContext, useEffect, useState } from 'react';
import { User, onAuthStateChanged, signOut } from 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, [auth]);

  // Session timeout handling
  useEffect(() => {
    if (!currentUser) return;

    // Check session every minute
    const interval = setInterval(() => {
      const user = auth.currentUser;
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const expirationTime = new Date(idTokenResult.expirationTime).getTime();
          const now = new Date().getTime();
          
          // If token expires in less than 5 minutes
          if (expirationTime - now < 5 * 60 * 1000) {
            toast.error('Your session has expired. Please login again.');
            logout();
            navigate('/');
          }
        });
      }
    }, 60 * 1000); // Check every minute

    return () => clearInterval(interval);
  }, [currentUser, auth, navigate]);

  const logout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  const value = {
    currentUser,
    loading,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
