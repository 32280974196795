import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getFirestore, collection, addDoc, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject, UploadTaskSnapshot } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import OfficialUseOnly from '../../components/OfficialUseOnly';
import { MemberType, Designation, MemberStatus } from '../../types/memberTypes';

interface UserData {
  fullName: string;
  email: string;
  phone: string;
  gender: string;
  dateOfBirth: string;
  aadhaarNumber: string;
  bloodGroup: string;
  fatherOrHusbandName: string;
  education: string;
  languagesKnown: string;
  identificationMark: string;
  profession: string;
  postHeld: string;
  currentAddressLine1: string;
  currentAddressLine2: string;
  currentState: string;
  currentDistrict: string;
  currentCityVillage: string;
  currentPinCode: string;
  currentStdCode: string;
  permanentAddressLine1: string;
  permanentAddressLine2: string;
  permanentState: string;
  permanentDistrict: string;
  permanentCityVillage: string;
  permanentPinCode: string;
  permanentStdCode: string;
  sameAsCurrent: boolean;
  vehicleMake: string;
  vehicleModel: string;
  vehicleRegistration: string;
  witness1Name: string;
  witness1Phone: string;
  witness1Aadhaar: string;
  witness2Name: string;
  witness2Phone: string;
  witness2Aadhaar: string;
  validFrom: string;
  validUntil: string;
  memberStatus: MemberStatus;
  memberType: MemberType;
  designation: Designation;
  branch?: string;
  officeState: string;
  officeDistrict: string;
  officeCityVillage: string;
  enrollmentNumber: string;
  range: string;
  rank: string;
  role: string;
  userRole: string;
  adminVerified: boolean;
  emailVerified: boolean;
  profileUpdated: boolean;
  verificationStatus: string;
  validity: string;
  createdAt?: Date | Timestamp;
  createdBy?: string;
  updatedAt?: Date | Timestamp;
  lastUpdatedBy?: string;
  history: any[];
  photo?: string;
  aadhaarFront?: string;
  aadhaarBack?: string;
  promissoryNote?: string;
  [key: string]: any;
}

interface UserHistory {
  action: 'created' | 'updated';
  timestamp: Date | Timestamp;
  updatedBy: string;
  changes?: {
    field: string;
    oldValue: any;
    newValue: any;
  }[];
}

interface Document {
  name: string;
  url: string;
}

interface UploadStatus {
  status: 'idle' | 'uploading' | 'completed' | 'error';
  progress: number;
  preview?: string;
}

interface UploadProgress {
  [key: string]: UploadStatus;
}

interface DocumentUploadProps {
  label: string;
  document?: {
    url: string;
    name: string;
    uploadProgress?: number;
  };
  onUpload: (file: File) => Promise<void>;
  onRemove: () => Promise<void>;
  onPreview: (url: string, title: string) => void;
  dimensions?: string;
}

const AddUser: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<UserData>({
    fullName: '',
    email: '',
    phone: '',
    gender: '',
    dateOfBirth: '',
    aadhaarNumber: '',
    bloodGroup: '',
    fatherOrHusbandName: '',
    education: '',
    languagesKnown: '',
    identificationMark: '',
    profession: '',
    postHeld: '',
    currentAddressLine1: '',
    currentAddressLine2: '',
    currentState: '',
    currentDistrict: '',
    currentCityVillage: '',
    currentPinCode: '',
    currentStdCode: '',
    permanentAddressLine1: '',
    permanentAddressLine2: '',
    permanentState: '',
    permanentDistrict: '',
    permanentCityVillage: '',
    permanentPinCode: '',
    permanentStdCode: '',
    sameAsCurrent: false,
    vehicleMake: '',
    vehicleModel: '',
    vehicleRegistration: '',
    witness1Name: '',
    witness1Phone: '',
    witness1Aadhaar: '',
    witness2Name: '',
    witness2Phone: '',
    witness2Aadhaar: '',
    validFrom: '',
    validUntil: '',
    memberStatus: 'Pending' as MemberStatus,
    memberType: 'Member' as MemberType,
    designation: '',
    branch: '',
    officeState: '',
    officeDistrict: '',
    officeCityVillage: '',
    enrollmentNumber: '',
    range: '',
    rank: '',
    role: '',
    userRole: 'user',
    adminVerified: false,
    emailVerified: false,
    profileUpdated: false,
    verificationStatus: '',
    validity: '',
    history: [],
    photo: '',
    aadhaarFront: '',
    aadhaarBack: '',
    promissoryNote: ''
  });
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({
    photo: { status: 'idle', progress: 0 },
    aadhaarFront: { status: 'idle', progress: 0 },
    aadhaarBack: { status: 'idle', progress: 0 },
    vehicleRegistration: { status: 'idle', progress: 0 },
    promissoryNote: { status: 'idle', progress: 0 }
  });
  const [previewUrls, setPreviewUrls] = useState<Record<string, string>>({});
  const [compressing, setCompressing] = useState(false);

  const getInputValue = (key: keyof UserData): string => {
    const value = userData[key];
    if (value instanceof Date) {
      return value.toISOString().split('T')[0];
    }
    if (typeof value === 'boolean') {
      return value.toString();
    }
    return value?.toString() || '';
  };

  const validatePincode = (pincode: string) => {
    const pincodeRegex = /^[0-9]{6}$/;
    return pincode === '' || pincodeRegex.test(pincode);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    // Validate pincode
    if ((name === 'currentPinCode' || name === 'permanentPinCode') && !validatePincode(value)) {
      toast.error('Pincode should be 6 digits');
      return;
    }

    setUserData(prev => {
      const newData = { ...prev, [name]: value };
      
      // If same as current is checked, update permanent address fields when current address fields change
      if (prev.sameAsCurrent && name.startsWith('current')) {
        const permanentField = 'permanent' + name.slice(7);
        newData[permanentField] = value;
      }
      
      return newData;
    });
  };

  const handleSameAsCurrentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setUserData(prev => ({
      ...prev,
      sameAsCurrent: checked,
      permanentAddressLine1: checked ? prev.currentAddressLine1 : '',
      permanentAddressLine2: checked ? prev.currentAddressLine2 : '',
      permanentState: checked ? prev.currentState : '',
      permanentDistrict: checked ? prev.currentDistrict : '',
      permanentCityVillage: checked ? prev.currentCityVillage : '',
      permanentPinCode: checked ? prev.currentPinCode : '',
      permanentStdCode: checked ? prev.currentStdCode : ''
    }));
  };

  const compressImage = async (file: File): Promise<File> => {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: file.type as 'image/jpeg' | 'image/png',
      initialQuality: 0.9, // Start with high quality
    };

    let compressedBlob = await imageCompression(file, options);
    
    // Convert Blob to File
    let compressedFile = new File(
      [compressedBlob],
      file.name,
      {
        type: compressedBlob.type,
        lastModified: file.lastModified
      }
    );
    
    // If the compressed file is larger than original, use the original
    if (compressedFile.size > file.size) {
      compressedFile = file;
    }

    // If still over 2MB, try progressive compression
    if (compressedFile.size > 2 * 1024 * 1024) {
      const progressiveOptions = {
        ...options,
        initialQuality: 0.8,
        maxSizeMB: 1.9 // Target slightly below 2MB
      };
      compressedBlob = await imageCompression(file, progressiveOptions);
      compressedFile = new File(
        [compressedBlob],
        file.name,
        {
          type: compressedBlob.type,
          lastModified: file.lastModified
        }
      );
    }

    return compressedFile;
  };

  const handleFileUpload = async (file: File, fieldName: string) => {
    if (!file) return;

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      
      if (!currentUser) {
        toast.error('You must be logged in to upload files');
        return;
      }

      setUploadProgress(prev => ({
        ...prev,
        [fieldName]: { status: 'uploading', progress: 0 }
      }));

      const storage = getStorage();
      // Include user UID in the storage path for security
      const fileRef = ref(storage, `users/${currentUser.uid}/${fieldName}/${Date.now()}_${file.name}`);
      
      // Compress image if it's an image file
      let fileToUpload: File | Blob = file;
      if (file.type.startsWith('image/')) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
        try {
          const compressedBlob = await imageCompression(file, options);
          // Convert Blob to File to maintain filename
          fileToUpload = new File([compressedBlob], file.name, {
            type: compressedBlob.type,
            lastModified: Date.now(),
          });
        } catch (error) {
          console.error('Error compressing image:', error);
          fileToUpload = file; // Use original file if compression fails
        }
      }

      const uploadTask = uploadBytesResumable(fileRef, fileToUpload);

      uploadTask.on('state_changed',
        (snapshot: UploadTaskSnapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(prev => ({
            ...prev,
            [fieldName]: { status: 'uploading', progress }
          }));
        },
        (error: Error) => {
          console.error(`Error uploading ${fieldName}:`, error);
          setUploadProgress(prev => ({
            ...prev,
            [fieldName]: { status: 'error', progress: 0 }
          }));
          toast.error(`Failed to upload ${fieldName}`);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUserData(prev => ({
            ...prev,
            [fieldName]: downloadURL
          }));
          setUploadProgress(prev => ({
            ...prev,
            [fieldName]: { status: 'completed', progress: 100 }
          }));
          toast.success(`${fieldName} uploaded successfully`);
        }
      );
    } catch (error) {
      console.error(`Error in ${fieldName} upload:`, error);
      toast.error(`Error uploading ${fieldName}`);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const file = e.target.files?.[0];
    if (file) {
      handleFileUpload(file, fieldName);
    }
  };

  const handleRemoveFile = async (fieldName: keyof Pick<UserData, 'photo' | 'aadhaarFront' | 'aadhaarBack' | 'vehicleRegistration' | 'promissoryNote'>) => {
    try {
      if (!userData[fieldName]) {
        return;
      }

      const auth = getAuth();
      const currentUser = auth.currentUser;
      
      if (!currentUser) {
        throw new Error('User must be authenticated to remove files');
      }

      // Create a reference to the file to delete
      const storage = getStorage();
      const fileUrl = userData[fieldName];
      const fileRef = ref(storage, fileUrl);

      // Delete the file
      await deleteObject(fileRef);

      // Reset the state
      setUserData(prev => ({
        ...prev,
        [fieldName]: ''
      }));

      setUploadProgress(prev => ({
        ...prev,
        [fieldName]: { status: 'idle', progress: 0 }
      }));

      toast.success(`${fieldName} removed successfully`);
    } catch (error) {
      console.error('Error removing file:', error);
      toast.error('Failed to remove file');
    }
  };

  const saveUserChanges = async (newData: UserData, existingData: UserData | null = null) => {
    const auth = getAuth();
    const db = getFirestore();
    
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    const now = Timestamp.fromDate(new Date());
    let historyEntry: UserHistory;

    if (!existingData) {
      // New user creation
      historyEntry = {
        action: 'created',
        timestamp: now,
        updatedBy: currentUser.email || 'unknown'
      };
    } else {
      // User update
      const changes = Object.keys(newData)
        .filter(key => {
          // Skip certain fields and check for actual changes
          if (['history', 'createdAt', 'updatedAt', 'createdBy', 'lastUpdatedBy'].includes(key)) {
            return false;
          }
          return JSON.stringify(newData[key]) !== JSON.stringify(existingData[key]);
        })
        .map(key => ({
          field: key,
          oldValue: existingData[key],
          newValue: newData[key]
        }));

      historyEntry = {
        action: 'updated',
        timestamp: now,
        updatedBy: currentUser.email || 'unknown',
        changes
      };
    }

    // Update the user data with new timestamps and history
    const updatedData = {
      ...newData,
      updatedAt: now,
      lastUpdatedBy: currentUser.email || 'unknown',
      history: [...(newData.history || []), historyEntry]
    };

    // If this is a new user, add creation info
    if (!existingData) {
      updatedData.createdAt = now;
      updatedData.createdBy = currentUser.email || 'unknown';
    }

    return { updatedData, historyEntry };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Get current date for validity
      const startDate = new Date();
      // Calculate one year from now for validity
      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);

      const userDataToSubmit = {
        ...userData,
        validFrom: startDate.toISOString().split('T')[0],  // Set current date
        validUntil: endDate.toISOString().split('T')[0],   // Set one year from now
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
      };

      // Create a Cloud Function trigger or scheduled function to check validity
      const userDataWithStatus = {
        ...userDataToSubmit,
        validityExpirationHandler: {
          validityDate: endDate,
          shouldDeactivate: true,
          lastChecked: Timestamp.fromDate(new Date())
        }
      };

      // 1. Create Firebase Authentication user
      const userCredential = await createUserWithEmailAndPassword(
        getAuth(),
        userDataToSubmit.email,
        'ccroindia@123' // Default password
      );

      // 2. Update user profile
      await updateProfile(userCredential.user, {
        displayName: `${userDataToSubmit.fullName}`,
      });

      // 3. Save user data with history
      const { updatedData, historyEntry } = await saveUserChanges(userDataWithStatus);

      // 4. Prepare user data for Firestore
      // Separate authentication-related data from user details
      const { email, ...userDetailsData } = updatedData;

      // 5. Create user document in userDetails collection
      const userDataWithFiles = {
        ...userDetailsData,
        authUid: userCredential.user.uid, // Add authUid field
        uid: userCredential.user.uid,
        photo: userData.photo || '',
        profileImage: userData.photo || '',  // Add profileImage field
        aadhaarFront: userData.aadhaarFront || '',
        aadhaarBack: userData.aadhaarBack || '',
        vehicleRegistration: userData.vehicleRegistration || '', // Save to vehicleRegistration field
        vehicleRegistrationNumber: userData.vehicleRegistration || '', // Also save to vehicleRegistrationNumber for consistency
        promissoryNote: userData.promissoryNote || '',
        range: userData.range || '',
        // Authentication reference data
        authEmail: email,
        authDisplayName: `${userDataToSubmit.fullName}`,
        // Timestamps and metadata
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
        // Validity and status tracking
        validFrom: userDataToSubmit.validFrom,
        validUntil: userDataToSubmit.validUntil,
        memberStatus: 'Active',
        validityExpirationHandler: {
          validityDate: userData.validUntil ? new Date(userData.validUntil) : endDate,
          shouldDeactivate: true,
          lastChecked: Timestamp.fromDate(new Date())
        }
      };

      // 6. Create document in userDetails collection
      const userDocRef = await addDoc(collection(getFirestore(), 'userDetails'), userDataWithFiles);

      // 7. Create history record
      await addDoc(collection(getFirestore(), 'userDetails', userDocRef.id, 'history'), {
        ...historyEntry,
        userId: userDocRef.id,
        authUid: userCredential.user.uid
      });

      // 8. Send verification email (optional)
      // await sendEmailVerification(userCredential.user);

      toast.success('User created successfully!');
      navigate('/admin/users');
    } catch (err) {
      console.error('Error creating user:', err);
      toast.error('Failed to create user');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEnrollmentNumberGenerated = (enrollmentNumber: string) => {
    setUserData(prev => ({
      ...prev,
      enrollmentNumber
    }));
  };

  const generateTemporaryPassword = () => {
    const length = 12;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*';
    let password = '';
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
  };

  const inputClassName = `
    w-full px-4 py-3 
    bg-slate-800/40 
    border border-slate-700/50 
    rounded-lg 
    focus:ring-2 focus:ring-emerald-500/50 focus:border-emerald-500/50 
    transition-all duration-200
    placeholder:text-slate-500
    text-slate-300
  `;

  const labelClassName = `
    block text-sm font-medium 
    text-slate-400 
    mb-1.5
  `;

  const sectionClassName = `
    bg-slate-900/50 
    backdrop-blur-sm 
    rounded-xl 
    border border-slate-800/50 
    p-6 md:p-8 
    space-y-6
    shadow-lg 
    shadow-slate-900/20
    transition-all duration-300 
    hover:shadow-slate-900/30
  `;

  const sectionHeaderClassName = `
    text-xl font-semibold 
    text-emerald-400
    mb-6 
    flex items-center gap-2
    border-b border-slate-800/50 
    pb-4
  `;

  const gridClassName = `
    grid grid-cols-1 
    md:grid-cols-2 
    lg:grid-cols-4 
    gap-6
  `;

  const buttonClassName = `
    px-6 py-2.5 
    rounded-lg 
    font-medium 
    transition-all duration-200 
    focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-900
  `;

  useEffect(() => {
    // Set validity to 1 year from today when member status becomes Active
    if (userData.memberStatus === 'Active' && !userData.validFrom) {
      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
      setUserData(prev => ({
        ...prev,
        validFrom: oneYearFromNow.toISOString().split('T')[0]
      }));
    }
  }, [userData.memberStatus]);

  const handleRemoveImage = async (fieldName: string) => {
    try {
      // If there's an existing file in storage, delete it
      if (userData[fieldName]) {
        const storage = getStorage();
        const fileUrl = userData[fieldName];
        const fileRef = ref(storage, fileUrl);
        await deleteObject(fileRef);
      }

      // Reset the state
      setUserData(prev => ({
        ...prev,
        [fieldName]: ''
      }));

      // Reset the preview URL
      setPreviewUrls(prev => ({
        ...prev,
        [fieldName]: ''
      }));

      // Reset upload progress
      setUploadProgress(prev => ({
        ...prev,
        [fieldName]: { status: 'idle', progress: 0 }
      }));

      toast.success('Image removed successfully');
    } catch (error) {
      console.error('Error removing image:', error);
      toast.error('Failed to remove image');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800 text-slate-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="flex items-center mb-6 space-x-4">
          <button
            type="button"
            onClick={() => navigate('/admin/users')}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            Back
          </button>
          <h1 className="text-3xl font-bold text-emerald-400">Add New Member</h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Authentication Details */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
              </svg>
              Authentication Details
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className={labelClassName}>Email ID</label>
                <input
                  type="email"
                  name="email"
                  value={getInputValue('email')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter email address"
                  required
                />
              </div>
              <div>
                <label className={labelClassName}>Password</label>
                <input
                  type="text"
                  value="ccroindia@123"
                  className={`${inputClassName} text-gray-500`}
                  placeholder="Default Password"
                  disabled
                />
              </div>
            </div>
          </div>

          {/* Personal Details */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              Personal Details
            </h2>
            <div className={gridClassName}>
              <div>
                <label className={labelClassName}>Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  value={getInputValue('fullName')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter full name"
                  required
                />
              </div>
              <div>
                <label className={labelClassName}>Email</label>
                <input
                  type="email"
                  name="email"
                  value={getInputValue('email')}
                  className={inputClassName}
                  placeholder="Email will be copied from Authentication Details"
                  disabled
                />
              </div>
              <div>
                <label className={labelClassName}>Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={getInputValue('phone')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter phone number"
                  required
                />
              </div>
              <div>
                <label className={labelClassName}>Gender</label>
                <select
                  name="gender"
                  value={getInputValue('gender')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                >
                  <option value="" className="bg-gray-700">Select Gender</option>
                  <option value="male" className="bg-gray-700">Male</option>
                  <option value="female" className="bg-gray-700">Female</option>
                  <option value="other" className="bg-gray-700">Other</option>
                </select>
              </div>
              <div>
                <label className={labelClassName}>Date of Birth</label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={getInputValue('dateOfBirth')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>
              <div>
                <label className={labelClassName}>Aadhaar Number</label>
                <input
                  type="text"
                  name="aadhaarNumber"
                  value={getInputValue('aadhaarNumber')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter Aadhaar number"
                  pattern="[0-9]{12}"
                  title="Please enter valid 12-digit Aadhaar number"
                  required
                />
              </div>
              <div>
                <label className={labelClassName}>Blood Group</label>
                <select
                  name="bloodGroup"
                  value={getInputValue('bloodGroup')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                >
                  <option value="" className="bg-gray-700">Select Blood Group</option>
                  <option value="A+" className="bg-gray-700">A+</option>
                  <option value="A-" className="bg-gray-700">A-</option>
                  <option value="B+" className="bg-gray-700">B+</option>
                  <option value="B-" className="bg-gray-700">B-</option>
                  <option value="AB+" className="bg-gray-700">AB+</option>
                  <option value="AB-" className="bg-gray-700">AB-</option>
                  <option value="O+" className="bg-gray-700">O+</option>
                  <option value="O-" className="bg-gray-700">O-</option>
                </select>
              </div>
              <div>
                <label className={labelClassName}>Father/Husband Name</label>
                <input
                  type="text"
                  name="fatherOrHusbandName"
                  value={getInputValue('fatherOrHusbandName')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter father's/husband's name"
                  required
                />
              </div>
              <div>
                <label className={labelClassName}>Education</label>
                <input
                  type="text"
                  name="education"
                  value={getInputValue('education')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter education details"
                  required
                />
              </div>
              <div>
                <label className={labelClassName}>Languages Known</label>
                <input
                  type="text"
                  name="languagesKnown"
                  value={getInputValue('languagesKnown')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter languages known (comma separated)"
                  required
                />
              </div>
              <div>
                <label className={labelClassName}>Identification Mark</label>
                <input
                  type="text"
                  name="identificationMark"
                  value={getInputValue('identificationMark')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter identification mark"
                  required
                />
              </div>
              <div>
                <label className={labelClassName}>Profession</label>
                <input
                  type="text"
                  name="profession"
                  value={getInputValue('profession')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter profession"
                  required
                />
              </div>
              <div>
                <label className={labelClassName}>Post Held</label>
                <input
                  type="text"
                  name="postHeld"
                  value={getInputValue('postHeld')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter post held"
                  required
                />
              </div>
            </div>
          </div>

          {/* Current Address */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              Current Address
            </h2>
            <div className={gridClassName}>
              <div>
                <label className={labelClassName}>Address Line 1</label>
                <input
                  type="text"
                  name="currentAddressLine1"
                  value={userData.currentAddressLine1}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
              </div>
              <div>
                <label className={labelClassName}>Address Line 2</label>
                <input
                  type="text"
                  name="currentAddressLine2"
                  value={userData.currentAddressLine2}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
              </div>
              <div>
                <label className={labelClassName}>State</label>
                <input
                  type="text"
                  name="currentState"
                  value={userData.currentState}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
              </div>
              <div>
                <label className={labelClassName}>District</label>
                <input
                  type="text"
                  name="currentDistrict"
                  value={userData.currentDistrict}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
              </div>
              <div>
                <label className={labelClassName}>City/Village</label>
                <input
                  type="text"
                  name="currentCityVillage"
                  value={userData.currentCityVillage}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
              </div>
              <div>
                <label className={labelClassName}>Pin Code</label>
                <input
                  type="text"
                  name="currentPinCode"
                  value={userData.currentPinCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
              </div>
              <div>
                <label className={labelClassName}>STD Code</label>
                <input
                  type="text"
                  name="currentStdCode"
                  value={userData.currentStdCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
              </div>
            </div>
          </div>

          {/* Permanent Address */}
          <div className={sectionClassName}>
            <div className="flex items-center justify-between mb-6">
              <h2 className={sectionHeaderClassName}>
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5m3-3h1" />
                </svg>
                Permanent Address
              </h2>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="sameAsCurrent"
                  name="sameAsCurrent"
                  checked={userData.sameAsCurrent}
                  onChange={handleSameAsCurrentChange}
                  className="w-4 h-4 rounded border-gray-500/30 bg-gray-800/50 
                   text-blue-500 focus:ring-blue-500/30 focus:ring-offset-0
                   transition-all duration-300"
                />
                <label htmlFor="sameAsCurrent" className="text-sm text-gray-300 hover:text-blue-400 transition-colors duration-300">
                  Same as Current Address
                </label>
              </div>
            </div>
            <div className={gridClassName}>
              <div>
                <label className={labelClassName}>Address Line 1</label>
                <input
                  type="text"
                  name="permanentAddressLine1"
                  value={userData.permanentAddressLine1}
                  onChange={handleInputChange}
                  className={inputClassName}
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={labelClassName}>Address Line 2</label>
                <input
                  type="text"
                  name="permanentAddressLine2"
                  value={userData.permanentAddressLine2}
                  onChange={handleInputChange}
                  className={inputClassName}
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={labelClassName}>State</label>
                <input
                  type="text"
                  name="permanentState"
                  value={userData.permanentState}
                  onChange={handleInputChange}
                  className={inputClassName}
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={labelClassName}>District</label>
                <input
                  type="text"
                  name="permanentDistrict"
                  value={userData.permanentDistrict}
                  onChange={handleInputChange}
                  className={inputClassName}
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={labelClassName}>City/Village</label>
                <input
                  type="text"
                  name="permanentCityVillage"
                  value={userData.permanentCityVillage}
                  onChange={handleInputChange}
                  className={inputClassName}
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={labelClassName}>Pin Code</label>
                <input
                  type="text"
                  name="permanentPinCode"
                  value={userData.permanentPinCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={labelClassName}>STD Code</label>
                <input
                  type="text"
                  name="permanentStdCode"
                  value={userData.permanentStdCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                  disabled={userData.sameAsCurrent}
                />
              </div>
            </div>
          </div>

          {/* Vehicle Details */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 16v3a2 2 0 01-2 2H7a2 2 0 01-2-2v-3m14 0V7a2 2 0 00-2-2H7a2 2 0 00-2 2v9m14 0h-3m-9 0H3m2 0h3m3-3h1" />
              </svg>
              Vehicle Details
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <label className={labelClassName}>Make</label>
                <input
                  type="text"
                  name="vehicleMake"
                  value={userData.vehicleMake || ''}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter vehicle make"
                />
              </div>
              <div>
                <label className={labelClassName}>Model</label>
                <input
                  type="text"
                  name="vehicleModel"
                  value={userData.vehicleModel || ''}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter vehicle model"
                />
              </div>
              <div>
                <label className={labelClassName}>Registration Number</label>
                <input
                  type="text"
                  name="vehicleRegistration"
                  value={userData.vehicleRegistration || ''}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter registration number"
                />
              </div>
            </div>
          </div>

          {/* Witness Details */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
              Witness Details
            </h2>
            
            {/* Witness 1 */}
            <div className="mb-6">
              <h3 className="text-lg font-medium mb-4 text-white border-b border-white/10 pb-2">Witness 1</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClassName}>Full Name</label>
                  <input
                    type="text"
                    name="witness1Name"
                    value={getInputValue('witness1Name')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter witness full name"
                    required
                  />
                </div>
                <div>
                  <label className={labelClassName}>Phone Number</label>
                  <input
                    type="tel"
                    name="witness1Phone"
                    value={getInputValue('witness1Phone')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter phone number"
                    pattern="[0-9]{10}"
                    title="Please enter valid 10-digit phone number"
                    required
                  />
                </div>
                <div>
                  <label className={labelClassName}>Aadhaar Number</label>
                  <input
                    type="text"
                    name="witness1Aadhaar"
                    value={getInputValue('witness1Aadhaar')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter Aadhaar number"
                    pattern="[0-9]{12}"
                    title="Please enter valid 12-digit Aadhaar number"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Witness 2 */}
            <div>
              <h3 className="text-lg font-medium mb-4 text-white border-b border-white/10 pb-2">Witness 2</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClassName}>Full Name</label>
                  <input
                    type="text"
                    name="witness2Name"
                    value={getInputValue('witness2Name')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter witness full name"
                    required
                  />
                </div>
                <div>
                  <label className={labelClassName}>Phone Number</label>
                  <input
                    type="tel"
                    name="witness2Phone"
                    value={getInputValue('witness2Phone')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter phone number"
                    pattern="[0-9]{10}"
                    title="Please enter valid 10-digit phone number"
                    required
                  />
                </div>
                <div>
                  <label className={labelClassName}>Aadhaar Number</label>
                  <input
                    type="text"
                    name="witness2Aadhaar"
                    value={getInputValue('witness2Aadhaar')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter Aadhaar number"
                    pattern="[0-9]{12}"
                    title="Please enter valid 12-digit Aadhaar number"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Membership Validity */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              Membership Validity
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className={labelClassName}>Valid From</label>
                <input
                  type="date"
                  name="validFrom"
                  value={getInputValue('validFrom')}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
                <p className="mt-1 text-sm text-gray-400">
                  If not specified, today's date will be used
                </p>
              </div>
              <div>
                <label className={labelClassName}>Valid Until</label>
                <input
                  type="date"
                  name="validUntil"
                  value={getInputValue('validUntil')}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
                <p className="mt-1 text-sm text-gray-400">
                  If not specified, validity will be set to 1 year from start date
                </p>
              </div>
            </div>
          </div>

          {/* Document Uploads */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              Document Uploads
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-2">Upload Photo</label>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, 'photo')}
                  accept="image/*"
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {uploadProgress.photo.status === 'uploading' && (
                  <div className="mt-2">
                    <div className="h-2 bg-slate-700 rounded-full">
                      <div
                        className="h-2 bg-blue-500 rounded-full transition-all duration-300"
                        style={{ width: `${uploadProgress.photo.progress}%` }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Aadhaar Card (Front)</label>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, 'aadhaarFront')}
                  accept="image/*"
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {uploadProgress.aadhaarFront.status === 'uploading' && (
                  <div className="mt-2">
                    <div className="h-2 bg-slate-700 rounded-full">
                      <div
                        className="h-2 bg-blue-500 rounded-full transition-all duration-300"
                        style={{ width: `${uploadProgress.aadhaarFront.progress}%` }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Aadhaar Card (Back)</label>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, 'aadhaarBack')}
                  accept="image/*"
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {uploadProgress.aadhaarBack.status === 'uploading' && (
                  <div className="mt-2">
                    <div className="h-2 bg-slate-700 rounded-full">
                      <div
                        className="h-2 bg-blue-500 rounded-full transition-all duration-300"
                        style={{ width: `${uploadProgress.aadhaarBack.progress}%` }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Vehicle Registration</label>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, 'vehicleRegistration')}
                  accept="image/*,.pdf"
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {uploadProgress.vehicleRegistration.status === 'uploading' && (
                  <div className="mt-2">
                    <div className="h-2 bg-slate-700 rounded-full">
                      <div
                        className="h-2 bg-blue-500 rounded-full transition-all duration-300"
                        style={{ width: `${uploadProgress.vehicleRegistration.progress}%` }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Promissory Note</label>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, 'promissoryNote')}
                  accept="image/*,.pdf"
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {uploadProgress.promissoryNote.status === 'uploading' && (
                  <div className="mt-2">
                    <div className="h-2 bg-slate-700 rounded-full">
                      <div
                        className="h-2 bg-blue-500 rounded-full transition-all duration-300"
                        style={{ width: `${uploadProgress.promissoryNote.progress}%` }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Verify Details */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
              Verify Details
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <label className={labelClassName}>Email Verification</label>
                <div className="mt-2">
                  <label className="inline-flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="emailVerified"
                      checked={userData.emailVerified}
                      onChange={(e) => setUserData(prev => ({
                        ...prev,
                        emailVerified: e.target.checked
                      }))}
                      className="w-4 h-4 rounded border-gray-500/30 bg-gray-800/50 
                        text-blue-500 focus:ring-blue-500/30 focus:ring-offset-0
                        transition-all duration-300"
                    />
                    <span className="text-sm text-gray-300">Email Verified</span>
                  </label>
                </div>
              </div>

              <div>
                <label className={labelClassName}>Profile Update Status</label>
                <div className="mt-2">
                  <label className="inline-flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="profileUpdated"
                      checked={userData.profileUpdated}
                      onChange={(e) => setUserData(prev => ({
                        ...prev,
                        profileUpdated: e.target.checked
                      }))}
                      className="w-4 h-4 rounded border-gray-500/30 bg-gray-800/50 
                        text-blue-500 focus:ring-blue-500/30 focus:ring-offset-0
                        transition-all duration-300"
                    />
                    <span className="text-sm text-gray-300">Profile Updated</span>
                  </label>
                </div>
              </div>

              <div>
                <label className={labelClassName}>User Role</label>
                <div className="mt-2 space-x-4">
                  <label className="inline-flex items-center gap-2">
                    <input
                      type="radio"
                      name="userRole"
                      value="user"
                      checked={userData.userRole === 'user'}
                      onChange={(e) => setUserData(prev => ({
                        ...prev,
                        userRole: e.target.value as 'user' | 'admin'
                      }))}
                      className="w-4 h-4 border-gray-500/30 bg-gray-800/50 
                        text-blue-500 focus:ring-blue-500/30 focus:ring-offset-0"
                    />
                    <span className="text-sm text-gray-300">User</span>
                  </label>
                  <label className="inline-flex items-center gap-2">
                    <input
                      type="radio"
                      name="userRole"
                      value="admin"
                      checked={userData.userRole === 'admin'}
                      onChange={(e) => setUserData(prev => ({
                        ...prev,
                        userRole: e.target.value as 'user' | 'admin'
                      }))}
                      className="w-4 h-4 border-gray-500/30 bg-gray-800/50 
                        text-blue-500 focus:ring-blue-500/30 focus:ring-offset-0"
                    />
                    <span className="text-sm text-gray-300">Admin</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Official Use Only */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
              Official Use Only
            </h2>
            <OfficialUseOnly
              userData={{
                ...userData,
                branch: userData.branch || ''
              }}
              onInputChange={handleInputChange}
              onEnrollmentNumberGenerated={handleEnrollmentNumberGenerated}
            />
          </div>

          {/* Form Actions */}
          <div className="sticky bottom-0 bg-slate-900/90 backdrop-blur-sm border-t border-slate-800 py-4 z-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={() => navigate('/admin/users')}
                  className={`${buttonClassName} bg-slate-800 text-slate-300 hover:bg-slate-700 hover:text-white border border-slate-700/50`}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`${buttonClassName} bg-emerald-600 text-white hover:bg-emerald-500 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2`}
                >
                  {isLoading ? (
                    <>
                      <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      Creating...
                    </>
                  ) : (
                    'Create Member'
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
