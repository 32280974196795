import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Event } from '../../types/event';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { FiImage, FiCalendar, FiClock, FiMapPin, FiDollarSign } from 'react-icons/fi';
import { Timestamp } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { format } from 'date-fns';

interface EventFormProps {
  event?: Partial<Event>;
  onSubmit: (data: Partial<Event>) => void;
  isLoading?: boolean;
  isEditing?: boolean;
}

interface EventFormRef {
  getFormData: () => Partial<Event> | null;
  updateFormData: (data: Partial<Event>) => void;
}

const EventForm = forwardRef<EventFormRef, EventFormProps>(({ event, onSubmit, isLoading = false, isEditing = false }, ref) => {
  const [formData, setFormData] = useState<Partial<Event>>({
    title: '',
    description: '',
    content: '',
    featuredImage: {
      url: '',
      alt: ''
    },
    location: '',
    price: 0,
    capacity: 0,
    status: 'draft',
    startDate: undefined,
    endDate: undefined,
    ...event,
  });

  const [editorState, setEditorState] = useState(() => {
    if (formData.content) {
      try {
        // Try parsing as Draft.js content first
        if (typeof formData.content === 'object') {
          return EditorState.createWithContent(convertFromRaw(formData.content));
        }
        // If it's a string, try parsing it as JSON
        const contentObj = JSON.parse(formData.content);
        return EditorState.createWithContent(convertFromRaw(contentObj));
      } catch {
        // If parsing fails, create empty editor
        return EditorState.createEmpty();
      }
    }
    return EditorState.createEmpty();
  });

  const handleEditorChange = (newState: EditorState) => {
    setEditorState(newState);
    const contentState = newState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    setFormData(prev => ({ ...prev, content: rawContent }));
  };

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const optimizeImage = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Target size: around 200KB for featured images
          const maxWidth = 1200;
          const maxHeight = 800;
          let quality = 0.7; // Start with 70% quality

          let width = img.width;
          let height = img.height;

          // Calculate new dimensions
          if (width > maxWidth) {
            height = (height * maxWidth) / width;
            width = maxWidth;
          }
          if (height > maxHeight) {
            width = (width * maxHeight) / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);

          // Convert to WebP format for better compression
          const optimizedImage = canvas.toDataURL('image/webp', quality);
          resolve(optimizedImage);
        };
        img.onerror = reject;
        img.src = reader.result as string;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      
      try {
        const optimizedImageUrl = await optimizeImage(file);
        setFormData(prev => ({
          ...prev,
          featuredImage: {
            url: optimizedImageUrl,
            alt: file.name
          }
        }));
      } catch (error) {
        console.error('Error optimizing image:', error);
        toast.error('Failed to process image. Please try another image.');
      }
    }
  };

  const validateFormData = () => {
    const data = { ...formData };

    if (!data.title?.trim()) {
      toast.error('Title is required');
      return null;
    }

    if (!data.description?.trim()) {
      toast.error('Description is required');
      return null;
    }

    if (!data.startDate || !data.endDate) {
      toast.error('Start and end dates are required');
      return null;
    }

    // Convert editor content to raw object
    const contentState = editorState.getCurrentContent();
    data.content = convertToRaw(contentState);

    if (!data.location?.trim()) {
      toast.error('Location is required');
      return null;
    }
    if (data.price && data.price < 0) {
      toast.error('Price cannot be negative');
      return null;
    }
    if (data.capacity && data.capacity < 0) {
      toast.error('Capacity cannot be negative');
      return null;
    }
    if (data.status === 'scheduled' && !data.scheduledAt) {
      toast.error('Scheduled date is required for scheduled events');
      return null;
    }
    return data;
  };

  const handleDateChange = (field: 'startDate' | 'endDate', value: string) => {
    try {
      if (!value) {
        setFormData(prev => ({ ...prev, [field]: null }));
        return;
      }
      
      // For start date, set time to start of day (00:00:00)
      // For end date, set time to end of day (23:59:59)
      const date = new Date(value);
      if (field === 'endDate') {
        date.setHours(23, 59, 59, 999);
      } else {
        date.setHours(0, 0, 0, 0);
      }
      
      setFormData(prev => ({
        ...prev,
        [field]: Timestamp.fromDate(date)
      }));
    } catch (error) {
      console.error(`Error setting ${field}:`, error);
      toast.error(`Invalid ${field.replace('Date', '')} date format`);
    }
  };

  useImperativeHandle(ref, () => ({
    getFormData: validateFormData,
    updateFormData: (newData: Partial<Event>) => {
      setFormData(prev => ({ ...prev, ...newData }));
    }
  }), [formData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = validateFormData();
    if (data) {
      onSubmit(data);
    }
  };

  const inputStyle = {
    backgroundColor: 'rgb(17, 24, 39)',
    color: 'white'
  };

  useEffect(() => {
    if (event) {
      setFormData(event);
    }
  }, [event]);

  return (
    <form className="p-4 sm:p-6" onSubmit={handleSubmit}>
      {/* Main Content Area */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {/* Left Column - Main Content */}
        <div className="lg:col-span-3 space-y-6">
          {/* Title and Description */}
          <div className="space-y-4 bg-white/10 p-4 sm:p-6 rounded-xl">
            <div>
              <label htmlFor="title" className="block text-base font-medium text-white mb-2">
                Title *
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-primary focus:border-primary transition-colors"
                style={inputStyle}
                placeholder="Enter event title"
                required
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-base font-medium text-white mb-2">
                Description *
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-primary focus:border-primary transition-colors"
                style={inputStyle}
                placeholder="Enter event description"
                rows={3}
                required
              />
            </div>
          </div>

          {/* Rich Text Editor */}
          <div className="bg-white/10 rounded-xl overflow-hidden mb-16">
            <div className="p-4 sm:p-6">
              <label className="block text-base font-medium text-white mb-2">
                Content *
              </label>
              <div className="prose prose-sm dark:prose-invert max-w-none">
                <div className="bg-gray-900 rounded-lg p-4" style={{ minHeight: '400px' }}>
                  <div className="mb-2 border-b border-gray-700 pb-2">
                    <button
                      type="button"
                      onClick={() => {
                        const newState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
                        handleEditorChange(newState);
                      }}
                      className="px-2 py-1 text-sm text-gray-300 hover:text-white"
                    >
                      Bold
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const newState = RichUtils.toggleInlineStyle(editorState, 'ITALIC');
                        handleEditorChange(newState);
                      }}
                      className="px-2 py-1 text-sm text-gray-300 hover:text-white"
                    >
                      Italic
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const newState = RichUtils.toggleInlineStyle(editorState, 'UNDERLINE');
                        handleEditorChange(newState);
                      }}
                      className="px-2 py-1 text-sm text-gray-300 hover:text-white"
                    >
                      Underline
                    </button>
                  </div>
                  <Editor
                    editorState={editorState}
                    onChange={handleEditorChange}
                    handleKeyCommand={handleKeyCommand}
                    placeholder="Write your event content here..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column - Meta Fields */}
        <div className="lg:col-span-1 space-y-6">
          {/* Featured Image */}
          <div className="bg-white/10 p-4 sm:p-6 rounded-xl space-y-4">
            <h3 className="flex items-center gap-2 text-lg font-medium text-white">
              <FiImage className="w-5 h-5" />
              Featured Image
            </h3>
            <div className="space-y-4">
              {formData.featuredImage?.url && (
                <div className="relative aspect-video rounded-lg overflow-hidden bg-gray-100">
                  <img
                    src={formData.featuredImage.url}
                    alt={formData.featuredImage.alt}
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="block w-full text-sm text-white
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-lg file:border-0
                    file:text-sm file:font-medium
                    file:bg-primary file:text-white
                    hover:file:cursor-pointer hover:file:bg-primary/80
                    hover:file:text-white"
                />
              </div>
            </div>
          </div>

          {/* Event Details */}
          <div className="bg-white/10 p-4 sm:p-6 rounded-xl space-y-4">
            <h3 className="flex items-center gap-2 text-lg font-medium text-white">
              <FiCalendar className="w-5 h-5" />
              Event Details
            </h3>
            <div className="space-y-4">
              <div>
                <label htmlFor="startDate" className="block text-sm font-medium text-gray-300 mb-2">
                  Start Date *
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={formData.startDate ? format(formData.startDate.toDate(), 'yyyy-MM-dd') : ''}
                  onChange={(e) => handleDateChange('startDate', e.target.value)}
                  className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors [color-scheme:dark]"
                  style={inputStyle}
                  required
                />
              </div>

              <div>
                <label htmlFor="endDate" className="block text-sm font-medium text-gray-300 mb-2">
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={formData.endDate ? format(formData.endDate.toDate(), 'yyyy-MM-dd') : ''}
                  onChange={(e) => handleDateChange('endDate', e.target.value)}
                  min={formData.startDate ? format(formData.startDate.toDate(), 'yyyy-MM-dd') : undefined}
                  className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors [color-scheme:dark]"
                  style={inputStyle}
                />
              </div>

              <div>
                <label htmlFor="location" className="block text-sm font-medium text-white mb-1">
                  Location *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FiMapPin className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    id="location"
                    value={formData.location}
                    onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value }))}
                    className="block w-full pl-10 pr-4 py-2 bg-gray-900 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-primary focus:border-primary transition-colors"
                    style={inputStyle}
                    placeholder="Enter event location"
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <div className="flex items-center">
                  <input
                    id="isFree"
                    type="checkbox"
                    checked={formData.price === 0}
                    onChange={(e) => {
                      setFormData(prev => ({
                        ...prev,
                        price: e.target.checked ? 0 : ((prev.price ?? 0) > 0 ? (prev.price ?? 0) : 100)
                      }));
                    }}
                    className="h-4 w-4 rounded border-gray-600 bg-gray-700 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-800"
                  />
                  <label htmlFor="isFree" className="ml-2 text-sm font-medium text-white">
                    Free Event
                  </label>
                </div>

                {formData.price === 0 ? null : (
                  <div>
                    <label htmlFor="price" className="block text-sm font-medium text-white mb-1">
                      Price (₹)
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-400">₹</span>
                      </div>
                      <input
                        type="number"
                        name="price"
                        id="price"
                        value={formData.price || ''}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          setFormData(prev => ({
                            ...prev,
                            price: isNaN(value) || value < 0 ? 0 : value
                          }));
                        }}
                        className="block w-full rounded-lg border-0 bg-gray-900 py-1.5 pl-8 pr-3 text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00"
                        min="0"
                        step="0.01"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div>
                <label htmlFor="capacity" className="block text-sm font-medium text-white mb-1">
                  Capacity
                </label>
                <input
                  type="number"
                  id="capacity"
                  min="0"
                  value={formData.capacity}
                  onChange={(e) => setFormData(prev => ({ ...prev, capacity: parseInt(e.target.value) }))}
                  className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors"
                  style={inputStyle}
                />
              </div>

              <div>
                <label htmlFor="status" className="block text-sm font-medium text-white mb-1">
                  Status
                </label>
                <select
                  id="status"
                  value={formData.status}
                  onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value as 'draft' | 'published' | 'scheduled' }))}
                  className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors appearance-none"
                  style={inputStyle}
                >
                  <option value="draft" className="bg-gray-900 text-white">Draft</option>
                  <option value="published" className="bg-gray-900 text-white">Published</option>
                  <option value="scheduled" className="bg-gray-900 text-white">Scheduled</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

export default EventForm;
