import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  collection, 
  addDoc, 
  onSnapshot, 
  deleteDoc,
  query, 
  orderBy,
  QuerySnapshot,
  DocumentSnapshot,
  DocumentData,
  getDocs
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { FaSpinner, FaPaperPlane, FaArrowLeft, FaUpload, FaDownload, FaFile, FaTrash, FaTimes } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';

interface Message {
  id: string;
  content: string;
  userId: string;
  userName: string;
  userDisplayName: string;
  userPhotoURL: string | null;
  createdAt: any;
  isSystem: boolean;
  isAdmin: boolean;
  fileURL?: string;
  fileName?: string;
  fileType?: string;
}

interface Ticket {
  id: string;
  title: string;
  description: string;
  status: 'open' | 'in-progress' | 'resolved';
  priority: 'low' | 'medium' | 'high';
  category: string;
  userId: string;
  userName: string;
  userDisplayName: string;
  userPhotoURL: string | null;
  isPrivate: boolean;
  createdAt: any;
  lastUpdated: any;
  unreadAdmin: boolean;
  unreadUser: boolean;
  lastMessage: string;
  lastMessageTime: any;
}

interface UserData {
  isAdmin?: boolean;
  role?: string;
  email?: string;
  displayName?: string;
}

const TicketDetail = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  // Add refs for unsubscribe functions
  const unsubTicketRef = useRef<(() => void) | undefined>();
  const unsubMessagesRef = useRef<(() => void) | undefined>();

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!currentUser) {
        console.log('No current user for admin check');
        setIsAdmin(false);
        return;
      }
      
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        
        // Check multiple admin conditions
        const isAdminUser = Boolean(
          userSnap.exists() && (
            userSnap.data()?.isAdmin === true ||
            userSnap.data()?.role === 'admin' ||
            currentUser.email === 'admin@gmail.com' ||
            currentUser.email === 'rohitgupta2079@gmail.com'
          )
        );
        
        console.log('Admin check in TicketDetail:', {
          email: currentUser.email,
          isAdmin: isAdminUser,
          userData: userSnap.data()
        });
        
        setIsAdmin(isAdminUser);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      }
    };
    checkAdminStatus();
  }, [currentUser]);

  useEffect(() => {
    if (!id || !currentUser) return;

    let setupSubscriptions = async () => {
      try {
        const ticketRef = doc(db, 'support_tickets', id);
        
        unsubTicketRef.current = onSnapshot(ticketRef, async (docSnapshot: DocumentSnapshot<DocumentData>) => {
          if (!docSnapshot.exists()) {
            // Only show "Ticket not found" if we're not in the process of deleting
            if (!loading) {
              toast.error('Ticket not found');
              navigate('/admin/support', { replace: true });
            }
            return;
          }

          const ticketData = { id: docSnapshot.id, ...docSnapshot.data() } as Ticket;
          
          try {
            // Check if user is admin or ticket owner
            const userRef = doc(db, 'users', currentUser.uid);
            const userSnap = await getDoc(userRef);
            const userData = userSnap.data() as UserData | undefined;
            
            const hasAccess = Boolean(
              userData?.isAdmin === true || 
              userData?.role === 'admin' ||
              currentUser.email === 'admin@gmail.com' ||
              currentUser.email === 'rohitgupta2079@gmail.com' ||
              ticketData.userId === currentUser.uid
            );

            if (!hasAccess) {
              console.log('Permission denied:', {
                ticketUserId: ticketData.userId,
                currentUserId: currentUser.uid
              });
              toast.error('You do not have permission to view this ticket');
              navigate('/admin/support', { replace: true });
              return;
            }

            setTicket(ticketData);
            setLoading(false);

            // Mark admin messages as read when admin views the ticket
            if (isAdmin && ticketData.unreadAdmin) {
              await updateDoc(ticketRef, {
                unreadAdmin: false
              });
            }
          } catch (error) {
            console.error('Error checking user permissions:', error);
            toast.error('Error checking permissions');
            navigate('/admin/support', { replace: true });
          }
        });

        const messagesRef = collection(db, 'support_tickets', id, 'messages');
        const messagesQuery = query(messagesRef, orderBy('createdAt', 'asc'));

        unsubMessagesRef.current = onSnapshot(messagesQuery, (snapshot: QuerySnapshot<DocumentData>) => {
          const messageData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as Message[];
          setMessages(messageData);
        });
      } catch (error) {
        console.error('Error setting up subscriptions:', error);
        toast.error('Error loading ticket');
        navigate('/admin/support', { replace: true });
      }
    };

    setupSubscriptions();

    return () => {
      if (typeof unsubTicketRef.current === 'function') {
        unsubTicketRef.current();
      }
      if (typeof unsubMessagesRef.current === 'function') {
        unsubMessagesRef.current();
      }
    };
  }, [id, currentUser, navigate, isAdmin, loading]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleStatusChange = async (newStatus: Ticket['status']) => {
    if (!ticket || !id || !isAdmin) {
      toast.error('Only admins can change ticket status');
      return;
    }

    try {
      const ticketRef = doc(db, 'support_tickets', id);
      await updateDoc(ticketRef, {
        status: newStatus,
        lastUpdated: new Date()
      });
      toast.success(`Ticket status updated to ${newStatus}`);
    } catch (error) {
      console.error('Error updating ticket status:', error);
      toast.error('Failed to update ticket status');
    }
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() || !ticket || !id || !currentUser) return;

    setSendingMessage(true);
    try {
      const messagesRef = collection(db, 'support_tickets', id, 'messages');
      const messageData = {
        content: newMessage.trim(),
        userId: currentUser.uid,
        userName: isAdmin ? 'Admin' : currentUser.email,
        userDisplayName: isAdmin ? 'Admin' : (currentUser.displayName || currentUser.email?.split('@')[0] || 'Anonymous'),
        userPhotoURL: currentUser.photoURL || null,
        createdAt: new Date(),
        isSystem: false,
        isAdmin
      };

      await addDoc(messagesRef, messageData);

      // Update ticket
      await updateDoc(doc(db, 'support_tickets', id), {
        lastMessage: newMessage.trim(),
        lastMessageTime: new Date(),
        lastUpdated: new Date(),
        unreadAdmin: !isAdmin,
        unreadUser: isAdmin
      });

      setNewMessage('');
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    } finally {
      setSendingMessage(false);
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !currentUser || !id) return;

    setUploading(true);
    try {
      // Create a reference to the file in Firebase Storage
      const fileRef = ref(storage, `support_tickets/${id}/${Date.now()}_${file.name}`);
      
      // Upload the file
      await uploadBytes(fileRef, file);
      
      // Get the download URL
      const downloadURL = await getDownloadURL(fileRef);

      // Add a new message with the file info
      const messageData = {
        content: `Uploaded file: ${file.name}`,
        userId: currentUser.uid,
        userName: isAdmin ? 'Admin' : currentUser.email,
        userDisplayName: isAdmin ? 'Admin' : (currentUser.displayName || currentUser.email?.split('@')[0] || 'Anonymous'),
        userPhotoURL: currentUser.photoURL || null,
        createdAt: new Date(),
        isSystem: false,
        isAdmin,
        fileURL: downloadURL,
        fileName: file.name,
        fileType: file.type
      };

      const messagesRef = collection(db, 'support_tickets', id, 'messages');
      await addDoc(messagesRef, messageData);

      // Update ticket
      await updateDoc(doc(db, 'support_tickets', id), {
        lastMessage: `Uploaded file: ${file.name}`,
        lastMessageTime: new Date(),
        lastUpdated: new Date(),
        unreadAdmin: !isAdmin,
        unreadUser: isAdmin
      });

      toast.success('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleFileDownload = async (message: Message) => {
    if (!message.fileURL || !message.fileName) return;

    try {
      // Get the download URL
      const response = await fetch(message.fileURL);
      const blob = await response.blob();

      // Create a download link
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = message.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Delete the file from storage
      const fileRef = ref(storage, message.fileURL);
      await deleteObject(fileRef);

      // Update the message to remove file info
      const messageRef = doc(db, 'support_tickets', id!, 'messages', message.id);
      await updateDoc(messageRef, {
        fileURL: null,
        fileName: null,
        fileType: null,
        content: `File was downloaded: ${message.fileName}`
      });

      toast.success('File downloaded and removed from storage');
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download file');
    }
  };

  const handleDeleteTicket = async () => {
    if (!ticket || !isAdmin) {
      toast.error('Only admins can delete tickets');
      return;
    }

    try {
      setLoading(true); // Prevent further updates while deleting

      // Delete all messages in the ticket
      const messagesRef = collection(db, 'support_tickets', id!, 'messages');
      const messagesSnapshot = await getDocs(messagesRef);
      const deletePromises = messagesSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);

      // Delete the ticket document
      const ticketRef = doc(db, 'support_tickets', id!);
      await deleteDoc(ticketRef);

      // Unsubscribe from all listeners before navigating
      if (typeof unsubTicketRef.current === 'function') {
        unsubTicketRef.current();
      }
      if (typeof unsubMessagesRef.current === 'function') {
        unsubMessagesRef.current();
      }

      toast.success('Ticket deleted successfully');
      
      // Use replace instead of push to prevent back navigation to deleted ticket
      navigate('/admin/support', { replace: true });
    } catch (error) {
      console.error('Error deleting ticket:', error);
      toast.error('Failed to delete ticket');
      setLoading(false);
    }
  };

  const handleCloseTicket = async () => {
    if (!ticket || !isAdmin) {
      toast.error('Only admins can close tickets');
      return;
    }

    try {
      const ticketRef = doc(db, 'support_tickets', id!);
      await updateDoc(ticketRef, {
        status: 'resolved',
        lastUpdated: new Date(),
        lastMessage: 'Ticket closed by admin'
      });

      // Add a system message about ticket closure
      const messagesRef = collection(db, 'support_tickets', id!, 'messages');
      await addDoc(messagesRef, {
        content: 'Ticket closed by admin',
        userId: currentUser?.uid,
        userName: currentUser?.email || 'Admin',
        userDisplayName: currentUser?.displayName || 'Admin',
        userPhotoURL: currentUser?.photoURL,
        createdAt: new Date(),
        isSystem: true,
        isAdmin: true
      });

      toast.success('Ticket closed successfully');
    } catch (error) {
      console.error('Error closing ticket:', error);
      toast.error('Failed to close ticket');
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'open':
        return 'bg-yellow-500';
      case 'in-progress':
        return 'bg-blue-500';
      case 'resolved':
        return 'bg-green-500';
      default:
        return 'bg-gray-500';
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'high':
        return 'bg-red-500';
      case 'medium':
        return 'bg-orange-500';
      case 'low':
        return 'bg-green-500';
      default:
        return 'bg-gray-500';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
          <FaSpinner className="text-4xl text-blue-500" />
        </motion.div>
      </div>
    );
  }

  if (!ticket) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="p-6 max-w-4xl mx-auto"
    >
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={() => navigate('/admin/support')}
          className="flex items-center text-gray-600 hover:text-gray-800"
        >
          <FaArrowLeft className="mr-2" /> Back to Support Dashboard
        </button>
        {isAdmin && ticket && (
          <div className="flex gap-2">
            <button
              onClick={handleCloseTicket}
              className="flex items-center px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors"
              disabled={ticket.status === 'resolved'}
            >
              <FaTimes className="mr-2" /> Close Ticket
            </button>
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="flex items-center px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
            >
              <FaTrash className="mr-2" /> Delete Ticket
            </button>
          </div>
        )}
      </div>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="bg-gray-800 rounded-xl p-6 shadow-lg mb-6"
      >
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold text-white mb-2">{ticket.title}</h1>
            <div className="flex flex-wrap gap-2">
              <span className={`px-3 py-1 rounded-full text-xs font-medium text-white ${getStatusColor(ticket.status)}`}>
                {ticket.status}
              </span>
              <span className={`px-3 py-1 rounded-full text-xs font-medium text-white ${getPriorityColor(ticket.priority)}`}>
                {ticket.priority}
              </span>
              <span className="px-3 py-1 rounded-full text-xs font-medium bg-gray-600 text-white">
                {ticket.category}
              </span>
            </div>
          </div>
          
          <div className="mt-4 md:mt-0">
            {isAdmin && ticket && (
              <select
                value={ticket.status}
                onChange={(e) => handleStatusChange(e.target.value as Ticket['status'])}
                className="px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
              >
                <option value="open">Open</option>
                <option value="in-progress">In Progress</option>
                <option value="resolved">Resolved</option>
              </select>
            )}
          </div>
        </div>

        <div className="text-gray-300 mb-4">{ticket.description}</div>

        <div className="text-sm text-gray-400">
          <p>Created by: {ticket.userName}</p>
          <p>Created: {new Date(ticket.createdAt?.toDate()).toLocaleString()}</p>
          <p>Last Updated: {new Date(ticket.lastUpdated?.toDate()).toLocaleString()}</p>
        </div>
      </motion.div>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="bg-gray-800 rounded-xl shadow-lg"
      >
        <div className="h-96 overflow-y-auto p-6 bg-gray-900 rounded-lg">
          <AnimatePresence>
            {messages.map((message) => {
              const isMyMessage = message.userId === currentUser?.uid;
              const isAdminMessage = message.isAdmin;

              return (
                <motion.div
                  key={message.id}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className={`flex ${isMyMessage ? 'justify-end' : 'justify-start'} mb-6`}
                >
                  {/* User/Admin Avatar - Show for non-my messages */}
                  {!isMyMessage && (
                    <div className="flex-shrink-0 mr-3">
                      <div className="relative">
                        {message.userPhotoURL ? (
                          <img
                            src={message.userPhotoURL}
                            alt={message.userDisplayName}
                            className="w-8 h-8 rounded-full border-2 border-gray-700"
                          />
                        ) : (
                          <div className={`w-8 h-8 rounded-full flex items-center justify-center border-2 border-gray-700 
                            ${isAdminMessage ? 'bg-purple-600' : 'bg-gray-600'}`}>
                            <span className="text-sm text-white">
                              {message.userDisplayName?.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                        {isAdminMessage && (
                          <div className="absolute -bottom-1 -right-1 bg-purple-500 rounded-full w-4 h-4 flex items-center justify-center">
                            <span className="text-[10px] text-white">A</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div
                    className={`max-w-[70%] rounded-lg px-4 py-2 ${
                      isMyMessage
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-700 text-white'
                    }`}
                  >
                    {/* Sender Name and Badge */}
                    <div className="flex items-center gap-2 mb-1">
                      <span className="text-xs opacity-80">
                        {isAdminMessage ? 'Admin' : message.userDisplayName}
                      </span>
                      {isAdminMessage && (
                        <span className="text-[10px] bg-gray-600 px-2 py-0.5 rounded-full text-white">
                          Admin
                        </span>
                      )}
                      {!isAdminMessage && (
                        <span className="text-[10px] bg-gray-600 px-2 py-0.5 rounded-full text-white">
                          User
                        </span>
                      )}
                    </div>

                    {/* Message Content */}
                    <div className="text-sm break-words">
                      {message.content}
                      {message.fileURL && (
                        <div className="mt-2 flex items-center gap-2">
                          <FaFile className="text-gray-400" />
                          <span className="text-sm text-gray-300">{message.fileName}</span>
                          <button
                            onClick={() => handleFileDownload(message)}
                            className="ml-2 text-blue-400 hover:text-blue-300 transition-colors"
                          >
                            <FaDownload />
                          </button>
                        </div>
                      )}
                    </div>

                    {/* Timestamp */}
                    <div className="text-xs opacity-70 text-right mt-1">
                      {new Date(message.createdAt?.toDate()).toLocaleTimeString([], { 
                        hour: '2-digit', 
                        minute: '2-digit' 
                      })}
                    </div>
                  </div>

                  {/* Admin Avatar - Show for my messages when admin */}
                  {isMyMessage && (
                    <div className="flex-shrink-0 ml-3">
                      <div className="relative">
                        {message.userPhotoURL ? (
                          <img
                            src={message.userPhotoURL}
                            alt={message.userDisplayName}
                            className="w-8 h-8 rounded-full border-2 border-gray-700"
                          />
                        ) : (
                          <div className={`w-8 h-8 rounded-full flex items-center justify-center border-2 border-gray-700 
                            ${isAdminMessage ? 'bg-purple-600' : 'bg-gray-600'}`}>
                            <span className="text-sm text-white">
                              {message.userDisplayName?.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                        {isAdminMessage && (
                          <div className="absolute -bottom-1 -right-1 bg-purple-500 rounded-full w-4 h-4 flex items-center justify-center">
                            <span className="text-[10px] text-white">A</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </motion.div>
              );
            })}
          </AnimatePresence>
          <div ref={messagesEndRef} />
        </div>

        <form onSubmit={handleSendMessage} className="p-4 border-t border-gray-700">
          <div className="flex gap-4">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type your message..."
              className="flex-1 px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
            />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              className="hidden"
              disabled={uploading}
            />
            <motion.button
              type="button"
              onClick={() => fileInputRef.current?.click()}
              disabled={uploading}
              className={`px-6 py-3 bg-gray-600 text-white rounded-lg flex items-center gap-2 transition-all duration-300 ${
                uploading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {uploading ? <FaSpinner className="animate-spin" /> : <FaUpload />}
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              disabled={sendingMessage || !newMessage.trim()}
              className={`px-6 py-3 bg-blue-500 text-white rounded-lg flex items-center gap-2 transition-all duration-300 ${
                sendingMessage || !newMessage.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
              }`}
            >
              {sendingMessage ? <FaSpinner className="animate-spin" /> : <FaPaperPlane />}
              Send
            </motion.button>
          </div>
        </form>
      </motion.div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Delete Ticket</h3>
            <p className="mb-6">Are you sure you want to delete this ticket? This action cannot be undone.</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleDeleteTicket();
                  setShowDeleteConfirm(false);
                }}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default TicketDetail;
