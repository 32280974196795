import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Article } from '../../types/article';
import toast from 'react-hot-toast';
import { FiArrowLeft, FiSave } from 'react-icons/fi';
import { useAuth } from '../../contexts/AuthContext';

const AddArticle = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [content, setContent] = useState('');
  const [featuredImage, setFeaturedImage] = useState<string>('');

  const handleSubmit = async () => {
    try {
      if (!currentUser) {
        toast.error('You must be logged in to create an article');
        return;
      }

      if (!heading.trim() || !content.trim()) {
        toast.error('Heading and content are required');
        return;
      }

      setIsLoading(true);
      
      const articleData: Partial<Article> = {
        heading,
        subHeading,
        content,
        featuredImage,
        createdAt: serverTimestamp() as any,
        updatedAt: serverTimestamp() as any
      };

      await addDoc(collection(db, 'articles'), articleData);
      toast.success('Article created successfully!');
      navigate('/articles');
    } catch (error) {
      console.error('Error creating article:', error);
      toast.error('Failed to create article');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-lg border-b border-white/10">
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4 flex items-center justify-between">
            {/* Left side */}
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate('/articles')}
                className="inline-flex items-center text-sm text-gray-400 hover:text-white transition-colors"
              >
                <FiArrowLeft className="w-4 h-4 mr-2" />
                Back to Articles
              </button>
            </div>

            {/* Right side */}
            <div className="flex items-center gap-3">
              <button
                onClick={handleSubmit}
                disabled={isLoading}
                className="inline-flex items-center px-4 py-2 rounded-lg bg-primary text-white text-sm font-medium hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary/50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                <FiSave className="w-4 h-4 mr-2" />
                {isLoading ? 'Saving...' : 'Save Article'}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="grid grid-cols-1 gap-6">
          <div className="bg-white/5 backdrop-blur-xl rounded-xl shadow-lg">
            <div className="p-6">
              <h2 className="text-lg font-semibold text-white mb-4">Article Content</h2>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-200 mb-2">Heading</label>
                  <input
                    type="text"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                    className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                    placeholder="Enter article heading"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-200 mb-2">
                    Sub Heading
                    <span className="text-xs text-gray-400 ml-2">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    value={subHeading}
                    onChange={(e) => setSubHeading(e.target.value)}
                    className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                    placeholder="Enter article sub heading"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-200 mb-2">Content</label>
                  <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                    rows={10}
                    placeholder="Enter article content"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-200 mb-2">
                    Featured Image URL
                    <span className="text-xs text-gray-400 ml-2">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    value={featuredImage}
                    onChange={(e) => setFeaturedImage(e.target.value)}
                    className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                    placeholder="Enter image URL"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddArticle;
