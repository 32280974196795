import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiPieChart, FiUsers, FiFileText, FiCalendar, FiImage, FiVideo, FiBell, FiSettings, FiChevronDown, FiLogOut, FiHeadphones, FiList, FiPlusCircle, FiArrowRight } from 'react-icons/fi';
import { getAuth, signOut } from 'firebase/auth';
import logo from '../images/logo.png';
import toast from 'react-hot-toast';

interface MenuItem {
  label: string;
  path?: string;
  icon: React.ReactNode;
  submenu?: {
    label: string;
    path: string;
    icon: React.ReactNode;
  }[];
}

interface AdminLayoutProps {
  children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems: MenuItem[] = [
    {
      label: 'Dashboard',
      path: '/admin/dashboard',
      icon: <FiPieChart size={20} />,
      submenu: [
        { label: 'Overview', path: '/admin/dashboard', icon: <FiList size={18} /> }
      ]
    },
    {
      label: 'Users',
      icon: <FiUsers size={20} />,
      submenu: [
        { label: 'All Users', path: '/admin/users', icon: <FiList size={18} /> },
        { label: 'Add User', path: '/admin/users/add', icon: <FiPlusCircle size={18} /> },
        { label: 'User Details', path: '/admin/users/details', icon: <FiList size={18} /> }
      ]
    },
    {
      label: 'Articles',
      icon: <FiFileText size={20} />,
      submenu: [
        { label: 'All Articles', path: '/admin/articles', icon: <FiList size={18} /> },
        { label: 'Add Article', path: '/admin/articles/add', icon: <FiPlusCircle size={18} /> }
      ]
    },
    {
      label: 'Events',
      icon: <FiCalendar size={20} />,
      submenu: [
        { label: 'All Events', path: '/admin/events', icon: <FiList size={18} /> },
        { label: 'Add Event', path: '/admin/events/add', icon: <FiPlusCircle size={18} /> }
      ]
    },
    {
      label: 'Photo Gallery',
      icon: <FiImage size={20} />,
      submenu: [
        { label: 'All Photos', path: '/admin/photo-gallery', icon: <FiList size={18} /> },
        { label: 'Add Photos', path: '/admin/photo-gallery/add', icon: <FiPlusCircle size={18} /> }
      ]
    },
    {
      label: 'Video Gallery',
      icon: <FiVideo size={20} />,
      submenu: [
        { label: 'All Videos', path: '/admin/video-gallery', icon: <FiList size={18} /> },
        { label: 'Add Videos', path: '/admin/video-gallery/add', icon: <FiPlusCircle size={18} /> }
      ]
    },
    {
      label: 'Notifications',
      icon: <FiBell size={20} />,
      submenu: [
        { label: 'All Notifications', path: '/admin/notifications', icon: <FiList size={18} /> },
        { label: 'Create Notification', path: '/admin/notifications/create', icon: <FiPlusCircle size={18} /> }
      ]
    },
    {
      label: 'Support',
      icon: <FiHeadphones size={20} />,
      submenu: [
        { label: 'Tickets', path: '/admin/support', icon: <FiList size={18} /> },
        { label: 'New Ticket', path: '/admin/support/new-ticket', icon: <FiPlusCircle size={18} /> }
      ]
    },
    {
      label: 'Settings',
      icon: <FiSettings size={20} />,
      submenu: [
        { label: 'General Settings', path: '/admin/settings', icon: <FiSettings size={18} /> }
      ]
    }
  ];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      setOpenMenu(null);
    }
  };

  const toggleSubmenu = (label: string) => {
    setOpenMenu(openMenu === label ? null : label);
  };

  const isSubmenuOpen = (label: string) => openMenu === label;
  const isActivePath = (path: string) => location.pathname === path;
  const isActiveSubmenu = (submenuItems: { path: string }[]) => 
    submenuItems.some(item => location.pathname === item.path);

  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      toast.success('Logged out successfully');
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Failed to logout');
    }
  };

  return (
    <div className="flex min-h-screen bg-dark-primary">
      {/* Sidebar */}
      <aside
        className={`fixed top-0 left-0 h-screen bg-dark-secondary z-30 transition-all duration-300 ease-in-out ${
          isSidebarOpen ? 'w-72' : 'w-20'
        }`}
      >
        {/* Logo and Brand */}
        <div className="h-16 flex items-center justify-between px-4 border-b border-gray-700">
          <div className="flex items-center space-x-3">
            {isSidebarOpen && (
              <>
                <img src={logo} alt="Logo" className="h-8 w-8" />
                <span className="text-xl font-bold text-white">Admin Panel</span>
              </>
            )}
          </div>
          <button
            onClick={toggleSidebar}
            className={`w-10 h-10 rounded-lg flex items-center justify-center hover:bg-gray-700/50 transition-all duration-300 ${
              !isSidebarOpen ? 'absolute left-5' : ''
            }`}
          >
            {isSidebarOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-300">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-300">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0110.5 3h6a2.25 2.25 0 012.25 2.25v13.5A2.25 2.25 0 0116.5 21h-6a2.25 2.25 0 01-2.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H3.75" />
              </svg>
            )}
          </button>
        </div>

        {/* Navigation Menu */}
        <div className="flex flex-col h-[calc(100vh-4rem)]">
          <nav className="flex-1 mt-4 px-2">
            {menuItems.map((item) => (
              <div key={item.label} className="mb-1">
                <button
                  onClick={() => toggleSubmenu(item.label)}
                  className={`w-full flex items-center justify-between p-3 rounded-lg transition-all duration-200 ${
                    isActiveSubmenu(item.submenu!) 
                      ? 'bg-blue-500/20 text-white' 
                      : 'text-gray-300 hover:bg-dark-hover/50 hover:text-white'
                  }`}
                >
                  <div className="flex items-center gap-3">
                    {item.icon}
                    {isSidebarOpen && <span className="font-medium">{item.label}</span>}
                  </div>
                  <FiChevronDown
                    className={`transform transition-transform duration-200 ${
                      isSubmenuOpen(item.label) ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                
                {/* Submenu */}
                <div
                  className={`overflow-hidden transition-all duration-200 ${
                    isSubmenuOpen(item.label) ? 'max-h-screen' : 'max-h-0'
                  }`}
                >
                  {item.submenu?.map((subItem) => (
                    <Link
                      key={subItem.path}
                      to={subItem.path}
                      className={`block ${
                        isSidebarOpen ? 'px-11' : 'px-6'
                      } py-2 rounded-lg my-1 transition-all duration-200 ${
                        isActivePath(subItem.path)
                          ? 'bg-blue-500/10 text-blue-400'
                          : 'text-gray-400 hover:bg-dark-hover/30 hover:text-white'
                      }`}
                    >
                      <div className={`flex items-center ${isSidebarOpen ? 'gap-2' : 'justify-center'}`}>
                        {subItem.icon}
                        {isSidebarOpen && subItem.label}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </nav>

          {/* Logout Button */}
          <div className="p-4 border-t border-gray-700">
            <button
              onClick={handleLogout}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 text-red-400 hover:text-red-300 hover:bg-red-400/10 rounded-lg transition-all duration-200"
            >
              <FiLogOut />
              {isSidebarOpen && <span>Logout</span>}
            </button>
          </div>
        </div>
      </aside>

      {/* Main Content */}
      <div className={`flex-1 ${isSidebarOpen ? 'ml-80' : 'ml-20'} transition-all duration-300`}>
        {/* Mobile Header */}
        <header className="sm:hidden h-16 flex items-center justify-between px-4 bg-dark-secondary border-b border-gray-700">
          <button
            onClick={toggleSidebar}
            className="p-2 rounded-lg hover:bg-dark-hover/50 transition-all duration-300"
          >
            <div className="w-6 h-5 relative transform transition-all duration-300">
              <span className={`absolute h-0.5 w-6 bg-white transform transition-all duration-300 ${
                isSidebarOpen ? 'rotate-45 top-2' : 'rotate-0 top-0'
              }`}></span>
              <span className={`absolute h-0.5 w-6 bg-white top-2 transition-all duration-300 ${
                isSidebarOpen ? 'opacity-0' : 'opacity-100'
              }`}></span>
              <span className={`absolute h-0.5 w-6 bg-white transform transition-all duration-300 ${
                isSidebarOpen ? '-rotate-45 top-2' : 'rotate-0 top-4'
              }`}></span>
            </div>
          </button>
          <div className="text-white font-semibold">Admin Dashboard</div>
          <div className="w-8"></div>
        </header>

        {/* Page Content */}
        <main className="p-6">
          {children}
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
