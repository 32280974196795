import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc, query, where, CollectionReference, Query } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Article } from '../../types/article';
import { FiEdit2, FiTrash2, FiPlus, FiSearch } from 'react-icons/fi';
import toast from 'react-hot-toast';
import ArticleCard from '../../components/articles/ArticleCard';
import { useAuth } from '../../contexts/AuthContext';

interface ArticlesListProps {
  articles?: Article[];
  loading?: boolean;
  showFilters?: boolean;
  emptyMessage?: string;
  userOnly?: boolean;
}

const ArticlesList: React.FC<ArticlesListProps> = ({
  articles: propArticles,
  loading: propLoading,
  showFilters = true,
  emptyMessage = "No articles found",
  userOnly = false
}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(propLoading ?? true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState<{
    isOpen: boolean;
    articleId: string;
    articleTitle: string;
  }>({
    isOpen: false,
    articleId: '',
    articleTitle: ''
  });
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);

        if (propArticles) {
          setArticles(propArticles);
          setLoading(false);
          return;
        }

        let articlesQuery: Query | CollectionReference = collection(db, 'articles');
        
        // If userOnly is true, only fetch user's articles
        if (userOnly && currentUser) {
          articlesQuery = query(
            collection(db, 'articles'),
            where('authorId', '==', currentUser.uid)
          );
        }

        const querySnapshot = await getDocs(articlesQuery);
        const fetchedArticles = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            heading: data.heading || 'Untitled',
            subHeading: data.subHeading || '',
            content: data.content || '',
            featuredImage: data.featuredImage || '',
            authorId: data.authorId || '',
            authorName: data.authorName || '',
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            status: data.status || 'draft'
          } as Article;
        });
        setArticles(fetchedArticles);
      } catch (error) {
        console.error('Error fetching articles:', error);
        setError('Failed to fetch articles');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [propArticles, currentUser, userOnly]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const filteredArticles = articles.filter(article => {
    const searchTerm = searchQuery.toLowerCase();
    return (
      article.heading.toLowerCase().includes(searchTerm) ||
      article.subHeading?.toLowerCase().includes(searchTerm) ||
      article.content?.toLowerCase().includes(searchTerm) || false
    );
  });

  const canEditArticle = (article: Article) => {
    if (!currentUser) return false;
    // Admin can edit all articles
    return true;
  };

  const canDeleteArticle = (article: Article) => {
    if (!currentUser) return false;
    // Admin can delete all articles
    return true;
  };

  const handleDelete = async (id: string, heading: string) => {
    const article = articles.find(a => a.id === id);
    if (!article) return;

    if (!canDeleteArticle(article)) {
      toast.error('You do not have permission to delete this article');
      return;
    }

    setDeleteModal({
      isOpen: true,
      articleId: id,
      articleTitle: heading
    });
  };

  const confirmDelete = async () => {
    if (!deleteModal.articleId) return;
    
    setIsDeleting(true);
    try {
      await deleteDoc(doc(db, 'articles', deleteModal.articleId));
      setArticles(prev => prev.filter(article => article.id !== deleteModal.articleId));
      toast.success('Article deleted successfully');
      setDeleteModal({ isOpen: false, articleId: '', articleTitle: '' });
    } catch (error) {
      console.error('Error deleting article:', error);
      toast.error('Failed to delete article');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      {/* Header */}
      {showFilters && (
        <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-xl border-b border-white/10">
          <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-6 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div className="flex-1 flex items-center gap-4">
                <h1 className="text-3xl font-bold text-white">Articles</h1>
                <div className="relative flex-1 max-w-md">
                  <input
                    type="text"
                    placeholder="Search articles..."
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="w-full px-4 py-2.5 bg-gray-800/50 border border-white/10 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50 focus:bg-gray-800/80"
                  />
                  <FiSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
              </div>
              <button
                onClick={() => navigate('/admin/articles/new')}
                className="inline-flex items-center px-5 py-2.5 bg-primary hover:bg-primary/90 text-white rounded-xl transition-colors shadow-lg shadow-primary/25"
              >
                <FiPlus className="w-5 h-5 mr-2" />
                New Article
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Content */}
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {loading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            {[1, 2, 3, 4].map((n) => (
              <div key={n} className="animate-pulse">
                <div className="bg-gray-800/50 rounded-xl p-6 border border-white/5">
                  <div className="h-4 bg-gray-700/50 rounded-lg w-3/4 mb-4"></div>
                  <div className="h-4 bg-gray-700/50 rounded-lg w-1/2 mb-4"></div>
                  <div className="flex gap-2">
                    <div className="h-4 bg-gray-700/50 rounded-lg w-24"></div>
                    <div className="h-4 bg-gray-700/50 rounded-lg w-24"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : error ? (
          <div className="text-center py-12">
            <div className="text-red-400 text-lg mb-2">Error loading articles</div>
            <div className="text-gray-400">{error}</div>
          </div>
        ) : filteredArticles.length === 0 ? (
          <div className="flex flex-col items-center justify-center py-12">
            <p className="text-gray-400 text-lg mb-4">{emptyMessage}</p>
            <button
              onClick={() => navigate('/admin/articles/new')}
              className="inline-flex items-center px-4 py-2 bg-primary/10 text-primary hover:bg-primary/20 rounded-lg transition-colors"
            >
              <FiPlus className="w-5 h-5 mr-2" />
              Create your first article
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            {filteredArticles.map((article) => (
              <ArticleCard
                key={article.id}
                article={article}
                onView={() => navigate(`/admin/articles/view/${article.id}`)}
                onEdit={() => navigate(`/admin/articles/edit/${article.id}`)}
                onDelete={() => handleDelete(article.id!, article.heading)}
                canEdit={canEditArticle(article)}
                canDelete={canDeleteArticle(article)}
                showAuthor={!userOnly}
              />
            ))}
          </div>
        )}
      </div>

      {/* Delete Modal */}
      {deleteModal.isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center px-4 bg-black/50">
          <div className="bg-gray-900 rounded-xl p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold text-white mb-4">Delete Article</h3>
            <p className="text-gray-400 mb-6">
              Are you sure you want to delete "{deleteModal.articleTitle}"? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setDeleteModal({ isOpen: false, articleId: '', articleTitle: '' })}
                disabled={isDeleting}
                className="px-4 py-2 text-gray-400 hover:text-white transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                disabled={isDeleting}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isDeleting ? 'Deleting...' : 'Delete'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticlesList;
