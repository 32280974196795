import React, { useState, useEffect } from 'react';
import { FiMail, FiSend, FiSettings } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

interface SMTPSettings {
  host: string;
  port: number;
  secure: boolean;
  auth: {
    user: string;
    pass: string;
  };
  source?: {
    firestore: boolean;
    config: boolean;
  };
}

const EmailSettings: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [testEmailLoading, setTestEmailLoading] = useState(false);
  const [smtpSettings, setSmtpSettings] = useState<SMTPSettings>({
    host: '',
    port: 587,
    secure: false,
    auth: {
      user: '',
      pass: ''
    }
  });
  const [testEmail, setTestEmail] = useState('');
  const [settingsSource, setSettingsSource] = useState<{ firestore: boolean; config: boolean }>({ firestore: false, config: false });

  // Fetch current SMTP settings
  useEffect(() => {
    const fetchSMTPSettings = async () => {
      try {
        setLoading(true);
        const getSmtpSettings = httpsCallable(getFunctions(), 'getSmtpSettings');
        const result = await getSmtpSettings();
        const data = result.data as SMTPSettings;
        
        if (data) {
          setSmtpSettings({
            host: data.host || '',
            port: data.port || 587,
            secure: data.secure || false,
            auth: {
              user: data.auth.user || '',
              pass: data.auth.pass || ''
            }
          });
          
          if (data.source) {
            setSettingsSource(data.source);
          }
        }
      } catch (error) {
        console.error('Error fetching SMTP settings:', error);
        toast.error('Failed to load SMTP settings');
      } finally {
        setLoading(false);
      }
    };

    fetchSMTPSettings();
  }, []);

  // Update SMTP settings
  const handleUpdateSMTPSettings = async () => {
    try {
      // Client-side validation
      if (!smtpSettings.host.trim()) {
        toast.error('SMTP host is required');
        return;
      }
      if (!smtpSettings.port || smtpSettings.port < 1 || smtpSettings.port > 65535) {
        toast.error('Invalid SMTP port. Must be between 1 and 65535');
        return;
      }
      if (!smtpSettings.auth.user.trim()) {
        toast.error('SMTP username is required');
        return;
      }
      if (!smtpSettings.auth.pass.trim()) {
        toast.error('SMTP password is required');
        return;
      }

      setLoading(true);
      const updateSmtpSettings = httpsCallable(getFunctions(), 'updateSmtpSettings');
      const result = await updateSmtpSettings(smtpSettings);
      const response = result.data as { success: boolean; message: string; configCommand?: string };
      
      if (response.success) {
        toast.success('SMTP settings updated successfully');
        
        if (response.configCommand) {
          await navigator.clipboard.writeText(response.configCommand);
          toast.success('Configuration command copied to clipboard. Please run this command in your terminal.');
          
          toast((t) => (
            <div>
              <p className="font-semibold mb-2">Please run this command in your terminal:</p>
              <div className="bg-gray-900 p-2 rounded mb-2 font-mono text-sm overflow-x-auto">
                {response.configCommand}
              </div>
              <button
                className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
                onClick={() => {
                  if (response.configCommand) {
                    navigator.clipboard.writeText(response.configCommand);
                    toast.success('Command copied to clipboard');
                  }
                }}
              >
                Copy Command
              </button>
            </div>
          ), {
            duration: 10000,
            position: 'bottom-center',
          });
        }
      } else {
        toast.error(response.message || 'Failed to update SMTP settings');
      }
    } catch (error) {
      console.error('Error updating SMTP settings:', error);
      if (error instanceof Error) {
        toast.error(`Failed to update SMTP settings: ${error.message}`);
      } else {
        toast.error('Failed to update SMTP settings');
      }
    } finally {
      setLoading(false);
    }
  };

  // Send test email
  const handleSendTestEmail = async () => {
    if (!testEmail) {
      toast.error('Please enter a test email address');
      return;
    }

    try {
      setTestEmailLoading(true);
      const sendTestEmail = httpsCallable(getFunctions(), 'sendTestEmail');
      await sendTestEmail({ to: testEmail });
      toast.success('Test email sent successfully');
      setTestEmail('');
    } catch (error) {
      console.error('Error sending test email:', error);
      toast.error('Failed to send test email');
    } finally {
      setTestEmailLoading(false);
    }
  };

  return (
    <div className="bg-gray-800 p-6 rounded-lg">
      <div className="flex items-center gap-4 mb-6">
        <FiSettings className="text-xl" />
        <h2 className="text-xl font-semibold">Email Settings</h2>
      </div>

      {/* Settings Source Indicator */}
      <div className="mb-6 p-4 bg-gray-700 rounded-lg">
        <h3 className="text-sm font-medium mb-2">Settings Source:</h3>
        <div className="flex gap-4">
          <div className={`flex items-center gap-2 ${settingsSource.firestore ? 'text-green-500' : 'text-gray-400'}`}>
            <div className={`w-2 h-2 rounded-full ${settingsSource.firestore ? 'bg-green-500' : 'bg-gray-400'}`} />
            <span>Firestore</span>
          </div>
          <div className={`flex items-center gap-2 ${settingsSource.config ? 'text-green-500' : 'text-gray-400'}`}>
            <div className={`w-2 h-2 rounded-full ${settingsSource.config ? 'bg-green-500' : 'bg-gray-400'}`} />
            <span>Firebase Config</span>
          </div>
        </div>
      </div>

      {/* SMTP Settings Form */}
      <div className="space-y-6 mb-8">
        <div>
          <label className="block text-sm font-medium mb-2">SMTP Host</label>
          <input
            type="text"
            value={smtpSettings.host}
            onChange={(e) => setSmtpSettings(prev => ({ ...prev, host: e.target.value }))}
            className="w-full px-4 py-2 bg-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="smtp.example.com"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">SMTP Port</label>
          <input
            type="number"
            value={smtpSettings.port}
            onChange={(e) => setSmtpSettings(prev => ({ ...prev, port: parseInt(e.target.value) || 587 }))}
            className="w-full px-4 py-2 bg-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={smtpSettings.secure}
            onChange={(e) => setSmtpSettings(prev => ({ ...prev, secure: e.target.checked }))}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label className="text-sm font-medium">Use SSL/TLS</label>
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">SMTP Username</label>
          <input
            type="text"
            value={smtpSettings.auth.user}
            onChange={(e) => setSmtpSettings(prev => ({ 
              ...prev, 
              auth: { ...prev.auth, user: e.target.value }
            }))}
            className="w-full px-4 py-2 bg-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="username@example.com"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">SMTP Password</label>
          <input
            type="password"
            value={smtpSettings.auth.pass}
            onChange={(e) => setSmtpSettings(prev => ({ 
              ...prev, 
              auth: { ...prev.auth, pass: e.target.value }
            }))}
            className="w-full px-4 py-2 bg-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="••••••••"
          />
        </div>

        <button
          onClick={handleUpdateSMTPSettings}
          disabled={loading}
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <>
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
              Updating...
            </>
          ) : (
            <>
              <FiSettings className="w-5 h-5" />
              Update SMTP Settings
            </>
          )}
        </button>
      </div>

      {/* Test Email Section */}
      <div className="border-t border-gray-700 pt-6">
        <div className="flex items-center gap-4 mb-6">
          <FiMail className="text-xl" />
          <h3 className="text-lg font-semibold">Test Email Configuration</h3>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-2">Test Email Address</label>
            <input
              type="email"
              value={testEmail}
              onChange={(e) => setTestEmail(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="test@example.com"
            />
          </div>

          <button
            onClick={handleSendTestEmail}
            disabled={testEmailLoading || !testEmail}
            className="w-full px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {testEmailLoading ? (
              <>
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                Sending...
              </>
            ) : (
              <>
                <FiSend className="w-5 h-5" />
                Send Test Email
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailSettings;
