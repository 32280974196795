import React from 'react';
import { Article } from '../../types/article';
import { FiEdit2, FiTrash2, FiUser, FiCalendar, FiEye } from 'react-icons/fi';
import { format } from 'date-fns';

interface ArticleCardProps {
  article: Article;
  onEdit: () => void;
  onDelete: () => void;
  onView: () => void;
  canEdit?: boolean;
  canDelete?: boolean;
  showAuthor?: boolean;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  article,
  onEdit,
  onDelete,
  onView,
  canEdit = true,
  canDelete = true,
  showAuthor = false
}) => {
  return (
    <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden hover:bg-gray-800/70 transition-all duration-300 border border-white/5">
      {/* Featured Image */}
      {article.featuredImage && (
        <div className="aspect-video relative group">
          <img
            src={article.featuredImage}
            alt={article.heading}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        </div>
      )}

      {/* Content */}
      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-xl font-semibold text-white line-clamp-2 flex-1">
            {article.heading}
          </h3>
          <div className="flex gap-2 ml-4">
            <button
              onClick={onView}
              className="p-2 text-gray-400 hover:text-blue-400 hover:bg-blue-400/10 rounded-lg transition-colors"
              title="View article"
            >
              <FiEye className="w-4 h-4" />
            </button>
            {canEdit && (
              <button
                onClick={onEdit}
                className="p-2 text-gray-400 hover:text-white hover:bg-gray-700/50 rounded-lg transition-colors"
                title="Edit article"
              >
                <FiEdit2 className="w-4 h-4" />
              </button>
            )}
            {canDelete && (
              <button
                onClick={onDelete}
                className="p-2 text-gray-400 hover:text-red-400 hover:bg-red-400/10 rounded-lg transition-colors"
                title="Delete article"
              >
                <FiTrash2 className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>

        {article.subHeading && (
          <p className="text-gray-400 mb-4 line-clamp-2">{article.subHeading}</p>
        )}

        <div className="flex items-center gap-4 text-sm text-gray-500">
          {showAuthor && article.authorName && (
            <div className="flex items-center gap-1.5">
              <FiUser className="w-4 h-4" />
              <span>{article.authorName}</span>
            </div>
          )}
          {article.createdAt && (
            <div className="flex items-center gap-1.5">
              <FiCalendar className="w-4 h-4" />
              <span>{format(article.createdAt.toDate(), 'MMM d, yyyy')}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
