import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { toast } from 'react-hot-toast';

interface SessionContextType {
  resetTimer: () => void;
  sessionTimeout: number;
  setSessionTimeout: (timeout: number) => void;
}

const SessionContext = createContext<SessionContextType | null>(null);

export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [sessionTimeout, setSessionTimeout] = useState(30); // Default 30 minutes
  const auth = getAuth();
  const db = getFirestore();

  const resetTimer = () => {
    setLastActivity(Date.now());
  };

  // Load user's session timeout setting
  useEffect(() => {
    const loadSessionTimeout = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const timeout = userData.security?.sessionTimeout;
            if (typeof timeout === 'number' && timeout > 0) {
              console.log('Loading session timeout:', timeout);
              setSessionTimeout(timeout);
            }
          }
        } catch (error) {
          console.error('Error loading session timeout:', error);
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        loadSessionTimeout();
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  // Check for inactivity
  useEffect(() => {
    if (!auth.currentUser) return;

    const interval = setInterval(async () => {
      const now = Date.now();
      const timeoutInMs = sessionTimeout * 60 * 1000; // Convert minutes to milliseconds
      
      if (now - lastActivity > timeoutInMs) {
        try {
          await signOut(auth);
          toast.success('Session expired. Please log in again.');
          navigate('/');
        } catch (error) {
          console.error('Error signing out:', error);
        }
      }
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, [lastActivity, sessionTimeout, navigate, auth]);

  // Reset timer on user activity
  useEffect(() => {
    const events = ['mousedown', 'keydown', 'scroll', 'touchstart'];
    
    const handleActivity = () => {
      resetTimer();
    };

    events.forEach(event => {
      window.addEventListener(event, handleActivity);
    });

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, []);

  const value = {
    resetTimer,
    sessionTimeout,
    setSessionTimeout
  };

  return (
    <SessionContext.Provider value={value}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};
