import { httpsCallable } from 'firebase/functions';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth, updateProfile as updateFirebaseProfile, updateEmail } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { 
  SecurityUpdateResponse, 
  Security2FASetupResponse, 
  Security2FAVerifyResponse,
  Security2FASetupData,
  Security2FAVerifyData
} from '../types/security';

interface UserSettings {
  profile: {
    name: string;
    email: string;
  };
  notifications: {
    email: boolean;
    push: boolean;
    desktop: boolean;
  };
  security: {
    twoFactor: boolean;
    sessionTimeout: number;
  };
}

// Initialize Firebase Functions
const functions = getFunctions();
const setup2FACall = httpsCallable<Security2FASetupData, Security2FASetupResponse>(
  functions, 
  'setup2FAFunction'
);
const verify2FACall = httpsCallable<Security2FAVerifyData, Security2FAVerifyResponse>(
  functions, 
  'verify2FAFunction'
);

// Profile Service
export const updateUserProfile = async (name: string, email: string) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    const db = getFirestore();
    const userRef = doc(db, 'users', user.uid);
    
    // Update Firestore profile
    await setDoc(userRef, {
      fullName: name,
      email: email,
      updatedAt: new Date(),
      profileUpdated: true
    }, { merge: true });

    // Update Firebase Auth profile if email changed
    if (email !== user.email) {
      await updateEmail(user, email);
    }

    // Update display name if changed
    if (name !== user.displayName) {
      await updateFirebaseProfile(user, { displayName: name });
    }
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

// Email Notification Service
export const updateEmailNotifications = async (enabled: boolean) => {
  try {
    console.log('Updating email notifications:', enabled);
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      console.error('No authenticated user');
      throw new Error('No authenticated user');
    }

    const functions = getFunctions();
    const updateEmailPreferences = httpsCallable<
      { emailNotifications: boolean },
      { success: boolean; message: string; enabled: boolean }
    >(functions, 'updateEmailPreferences');

    const result = await updateEmailPreferences({ emailNotifications: enabled });
    console.log('Email preferences update result:', result.data);

    // Update Firestore
    const db = getFirestore();
    await setDoc(
      doc(db, 'users', user.uid),
      {
        notifications: {
          email: enabled,
          updatedAt: new Date()
        }
      },
      { merge: true }
    );

    return result.data;
  } catch (error) {
    console.error('Error updating email notifications:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to update email notifications: ${error.message}`);
    }
    throw error;
  }
};

// Push Notification Service
export const updatePushNotifications = async (enabled: boolean) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    if (enabled) {
      try {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          throw new Error('Push notification permission denied');
        }
      } catch (error) {
        throw new Error('Push notifications not supported');
      }
    }

    const db = getFirestore();
    const userRef = doc(db, 'users', user.uid);
    await setDoc(userRef, {
      notifications: { push: enabled },
      updatedAt: new Date(),
    }, { merge: true });
  } catch (error) {
    console.error('Error updating push notifications:', error);
    throw error;
  }
};

// Desktop Notification Service
export const updateDesktopNotifications = async (enabled: boolean) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    const db = getFirestore();
    const userRef = doc(db, 'users', user.uid);
    await setDoc(userRef, {
      notifications: { desktop: enabled },
      updatedAt: new Date(),
    }, { merge: true });
  } catch (error) {
    console.error('Error updating desktop notifications:', error);
    throw error;
  }
};

// Security Service
export const updateSecuritySettings = async (
  twoFactor: boolean,
  sessionTimeout: number
): Promise<SecurityUpdateResponse> => {
  try {
    console.log('Updating security settings:', { twoFactor, sessionTimeout });
    
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      console.error('No user logged in');
      throw new Error('No user logged in');
    }

    const db = getFirestore();
    const userRef = doc(db, 'users', user.uid);

    // First, get the current security settings
    const userDoc = await getDoc(userRef);
    console.log('Current user doc:', userDoc.data());
    const currentSettings = userDoc.data()?.security || {};

    // Prepare the update object
    const securityUpdate = {
      security: {
        ...currentSettings,
        sessionTimeout,
        twoFactor,
        updatedAt: new Date()
      }
    };
    console.log('Preparing security update:', securityUpdate);

    try {
      // Update security settings in Firestore
      await setDoc(userRef, securityUpdate, { merge: true });
      console.log('Successfully updated security settings in Firestore');
    } catch (firestoreError) {
      console.error('Error updating Firestore:', firestoreError);
      throw firestoreError;
    }

    // If enabling 2FA, set it up
    if (twoFactor) {
      try {
        console.log('Setting up 2FA...');
        const result = await setup2FACall({});
        console.log('2FA setup successful:', result);
        return {
          success: true,
          qrCode: result.data.qrCode,
          secret: result.data.secret
        };
      } catch (twoFactorError) {
        console.error('Error setting up 2FA:', twoFactorError);
        // Don't throw here, as we still want to return success for the timeout update
        return { success: true };
      }
    }

    return { success: true };
  } catch (error) {
    console.error('Error in updateSecuritySettings:', error);
    if (error instanceof Error) {
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
    }
    throw error;
  }
};

// Get User Settings
export const getUserSettings = async (): Promise<UserSettings | null> => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) return null;

  try {
    const db = getFirestore();
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    
    // Get data from Firestore
    const firestoreData = userDoc.exists() ? userDoc.data() : null;
    
    // Debug log
    console.log('Firestore data:', firestoreData);
    
    // Get the name from various possible sources
    const name = firestoreData?.fullName || 
                 firestoreData?.authDisplayName || 
                 user.displayName || 
                 '';
                 
    console.log('Selected name:', name);
    
    // Combine Firebase Auth and Firestore data
    return {
      profile: {
        name: name,
        email: firestoreData?.email || user.email || '',
      },
      notifications: firestoreData?.notifications || {
        email: false,
        push: false,
        desktop: false,
      },
      security: {
        twoFactor: firestoreData?.security?.twoFactor || false,
        sessionTimeout: firestoreData?.security?.sessionTimeout || 30,
      },
    };
  } catch (error) {
    console.error('Error getting user settings:', error);
    throw error;
  }
};
