import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Event } from '../../types/event';
import { format } from 'date-fns';
import { FiArrowLeft, FiEdit2, FiTrash2, FiCalendar, FiMapPin, FiClock } from 'react-icons/fi';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { toast } from 'react-hot-toast';

const ViewEvent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState<EditorState | null>(null);

  useEffect(() => {
    const fetchEvent = async () => {
      if (!id) return;

      try {
        setLoading(true);
        const eventDoc = await getDoc(doc(db, 'events', id));
        
        if (!eventDoc.exists()) {
          toast.error('Event not found');
          navigate('/admin/events');
          return;
        }

        const eventData = { id: eventDoc.id, ...eventDoc.data() } as Event;
        setEvent(eventData);

        // Initialize editor state with content
        if (eventData.content) {
          try {
            const contentState = typeof eventData.content === 'string' 
              ? convertFromRaw(JSON.parse(eventData.content))
              : convertFromRaw(eventData.content);
            setEditorState(EditorState.createWithContent(contentState));
          } catch (err) {
            console.error('Error parsing content:', err);
            setEditorState(EditorState.createEmpty());
          }
        }
      } catch (err) {
        console.error('Error fetching event:', err);
        toast.error('Failed to fetch event');
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id, navigate]);

  const formatDate = (timestamp: any) => {
    if (!timestamp) return 'N/A';
    try {
      return format(timestamp.toDate(), 'MMM d, yyyy h:mm a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!event) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h2 className="text-xl text-gray-400 mb-4">Event not found</h2>
        <button
          onClick={() => navigate('/admin/events')}
          className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/80 transition-colors"
        >
          Back to Events
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-lg border-b border-white/10">
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4 flex items-center justify-between">
            {/* Left side */}
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate('/admin/events')}
                className="inline-flex items-center text-sm text-gray-400 hover:text-white transition-colors"
              >
                <FiArrowLeft className="w-4 h-4 mr-2" />
                Back to Events
              </button>
            </div>

            {/* Right side */}
            <div className="flex items-center gap-3">
              <button
                onClick={() => navigate(`/admin/events/edit/${event.id}`)}
                className="inline-flex items-center px-4 py-2 rounded-lg bg-primary text-white text-sm font-medium hover:bg-primary/90 transition-colors"
              >
                <FiEdit2 className="w-4 h-4 mr-2" />
                Edit Event
              </button>
              <button
                onClick={() => navigate(`/admin/events/delete/${event.id}`)}
                className="inline-flex items-center px-4 py-2 rounded-lg bg-red-600 text-white text-sm font-medium hover:bg-red-700 transition-colors"
              >
                <FiTrash2 className="w-4 h-4 mr-2" />
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="bg-white/5 backdrop-blur-xl rounded-xl shadow-xl overflow-hidden">
          {/* Featured Image and Header */}
          <div className="relative">
            {event.featuredImage ? (
              <div className="relative h-[300px] lg:h-[400px]">
                <img
                  src={event.featuredImage.url}
                  alt={event.featuredImage.alt || event.title}
                  className="w-full h-full object-cover"
                />
                {/* Gradient Overlay */}
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/50 to-transparent" />
              </div>
            ) : (
              <div className="h-[100px] bg-gradient-to-r from-gray-800 to-gray-700" />
            )}

            {/* Event Header - Overlaid on image */}
            <div className="relative p-6 sm:p-8">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <span className={`
                    px-3 py-1 rounded-full text-xs font-medium
                    ${event.status === 'published' ? 'bg-green-500/20 text-green-400' :
                      event.status === 'draft' ? 'bg-gray-500/20 text-gray-400' :
                      'bg-yellow-500/20 text-yellow-400'}
                  `}>
                    {event.status.charAt(0).toUpperCase() + event.status.slice(1)}
                  </span>
                </div>
                <h1 className="text-2xl sm:text-3xl font-bold text-white">{event.title}</h1>
                <p className="text-gray-300">{event.description}</p>

                {/* Quick Info */}
                <div className="flex flex-wrap gap-4 mt-2">
                  <div className="flex items-center gap-2 text-gray-300">
                    <FiCalendar className="w-4 h-4 text-primary" />
                    <span>{formatDate(event.startDate)}</span>
                  </div>
                  {event.location && (
                    <div className="flex items-center gap-2 text-gray-300">
                      <FiMapPin className="w-4 h-4 text-primary" />
                      <span>{event.location}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Event Content */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 p-6 sm:p-8 border-t border-white/10">
            {/* Left Column - Event Information */}
            <div className="lg:col-span-2 space-y-6">
              {/* Date and Time */}
              <div className="bg-white/5 rounded-xl p-6 space-y-4">
                <h2 className="text-xl font-semibold text-white">Date & Time</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="flex items-center gap-3 text-gray-300">
                    <FiCalendar className="w-5 h-5 text-primary" />
                    <div>
                      <p className="text-sm text-gray-400">Start</p>
                      <p>{formatDate(event.startDate)}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3 text-gray-300">
                    <FiClock className="w-5 h-5 text-primary" />
                    <div>
                      <p className="text-sm text-gray-400">End</p>
                      <p>{formatDate(event.endDate)}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Content */}
              <div className="mt-8">
                <h2 className="text-xl font-semibold text-white mb-4">Event Content</h2>
                <div className="bg-gray-800 rounded-lg p-6">
                  {editorState ? (
                    <div className="prose prose-invert max-w-none">
                      <Editor
                        editorState={editorState}
                        onChange={() => {}}
                        readOnly={true}
                      />
                    </div>
                  ) : (
                    <p className="text-gray-400">No content available</p>
                  )}
                </div>
              </div>
            </div>

            {/* Right Column - Meta Information */}
            <div className="space-y-6">
              {/* Meta Information */}
              <div className="bg-white/5 rounded-xl p-6 space-y-4">
                <h2 className="text-xl font-semibold text-white">Event Details</h2>
                <div className="space-y-3">
                  <div>
                    <p className="text-sm text-gray-400">Created</p>
                    <p className="text-gray-300">{formatDate(event.createdAt)}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Last Modified</p>
                    <p className="text-gray-300">{formatDate(event.updatedAt)}</p>
                  </div>
                  {event.publishedAt && (
                    <div>
                      <p className="text-sm text-gray-400">Published</p>
                      <p className="text-gray-300">{formatDate(event.publishedAt)}</p>
                    </div>
                  )}
                  {event.scheduledAt && (
                    <div>
                      <p className="text-sm text-gray-400">Scheduled</p>
                      <p className="text-gray-300">{formatDate(event.scheduledAt)}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEvent;
